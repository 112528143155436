<div class="dialog">
	<div class="dialog-header">选择{{ProjectItemTypeMap[type!]}}</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row">
				<p>手动选择或者至少<span class="color-primary">输入2个字符</span>进行模糊搜索:</p>
			</div>
			<div class="row validate">
				<div class="column no-border">
					<wbs-unit-cascader [unitCascaderValue]="unitCascaderValue" class="color-primary"
						[style.width]="60 + 'rem'" [type]="type"
						(unitCascaderValueChange)="onUnitCascaderValueChange($event)"></wbs-unit-cascader>
				</div>
			</div>
			<div class="row" *ngIf="history.length > 0">
				<p>历史记录</p>
			</div>
			<div class="row" *ngFor="let item of history; let index = index" (click)="chooseHistory(index)">
				<div class="column no-border">
					<wbs-unit-cascader [unitCascaderValue]="item" [disabled]="true" class="color-primary"
						[style.width]="60 + 'rem'" [type]="type"></wbs-unit-cascader>
					<div class="shield">

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class=" dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>