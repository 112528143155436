export enum FimpMeasuredValueFillType {
  POP_UP = 'POP_UP',
  ORIGINAL_FORM = 'ORIGINAL_FORM',
}

export const FimpMeasuredValueFillTypeMap = {
  [FimpMeasuredValueFillType.POP_UP]: '弹出填写',
  [FimpMeasuredValueFillType.ORIGINAL_FORM]: '原表填写',
};

export const FimpMeasuredValueFillTypeOptions = [
  {
    label: FimpMeasuredValueFillTypeMap[FimpMeasuredValueFillType.POP_UP],
    value: FimpMeasuredValueFillType.POP_UP,
  },
  {
    label:
      FimpMeasuredValueFillTypeMap[FimpMeasuredValueFillType.ORIGINAL_FORM],
    value: FimpMeasuredValueFillType.ORIGINAL_FORM,
  },
];
