import { SubFormRouter } from 'src/app/modules/shared/models/form/sub-form-router.model';
import { ProjectSubformService } from 'src/app/modules/shared/services/project-sub-form.service';
import { ProjectSectionItemService } from 'src/app/modules/shared/services/project-section-item.service';
import { Component, Input } from '@angular/core';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import {
  ProjectItemType,
  ProjectItemTypeMap,
} from 'src/app/modules/shared/models/project/project-item-type.enum';
import { ProjectSectionItem } from 'src/app/modules/shared/models/project/project-section-item.model';
import { SubFormRouterListRequest } from 'src/app/modules/shared/models/form/sub-form-router-list-request.model';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';

@Component({
  selector: 'app-dialog-sub-form-choose',
  templateUrl: './dialog-sub-form-choose.component.html',
  styleUrls: ['./dialog-sub-form-choose.component.scss'],
})
export class DialogSubFormChooseComponent {
  // TODO 增加历史记录
  @Input()
  data!: DialogConfig<DialogSubFormChooseData>;

  editing: { [name: string]: boolean } = {};

  unitCascaderValue: any[] = [];

  type: ProjectItemType = ProjectItemType.SECTION;

  record!: DialogSubFormChooseData;

  subFormList: SubFormRouter[] = [];
  ProjectItemTypeMap = ProjectItemTypeMap;

  selecedIndex = -1;
  history: any[][] = [];

  onUnitCascaderValueChange(event: any[]) {
    console.log(event);
    this.unitCascaderValue = event;
    this.selecedIndex = -1;
    this.subFormList = [];
    if (this.unitCascaderValue.length > 0) {
      this.searchFormList(
        this.unitCascaderValue[this.unitCascaderValue.length - 1]
      );
    }
  }

  private searchFormList(sectionItemId: number) {
    this.projectSectionItemService
      .findById(sectionItemId)
      .subscribe((response: ProjectSectionItem) => {
        if (response) {
          this.projectSubformService
            .findAll<SubFormRouterListRequest>({
              typeCode: response.projectTypeCode,
              typeFormCode: this.record!.formCode?.trim(),
              projectId: this.globalService.projectId,
            })
            .subscribe((response: SubFormRouter[]) => {
              this.subFormList = [];
              for (const item of response ?? []) {
                if (item.formId) {
                  this.subFormList.push(item);
                }
              }
              if (this.subFormList.length === 1) {
                this.selecedIndex = 0;
              }
            });
        }
      });
  }

  constructor(
    private projectSectionItemService: ProjectSectionItemService,
    private projectSubformService: ProjectSubformService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    if (this.data.data) {
      // this.type = this.data.data;
      this.record = this.data.data;
      if (this.record.sectionItemId) {
        this.searchFormList(this.record.sectionItemId);
      } else {
        this.history =
          this.globalService.getFormCodeWbsHistory(this.data.data.formCode!) ??
          [];
      }
    }
    console.log(this.type);
  }

  chooseHistory(index: number) {
    this.unitCascaderValue = JSON.parse(JSON.stringify(this.history[index]));
    this.selecedIndex = -1;
    this.subFormList = [];
    if (this.unitCascaderValue.length > 0) {
      this.searchFormList(
        this.unitCascaderValue[this.unitCascaderValue.length - 1]
      );
    }
  }

  onBlur() {
    this.editing = {};
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  get canConfirm() {
    return (
      (this.unitCascaderValue.length > 0 || this.record.sectionItemId) &&
      this.selecedIndex > -1
    );
  }

  private submit() {
    const result: DialogSubFormChooseResult = {
      formId: this.subFormList[this.selecedIndex].formId,
    };
    if (this.record.sectionItemId) {
      result.sectionItemId = this.record.sectionItemId;
    } else if (this.unitCascaderValue) {
      result.sectionItemId =
        this.unitCascaderValue[this.unitCascaderValue.length - 1];
      result.sectionId = this.unitCascaderValue[0];

      let contain = false;
      for (const item of this.history) {
        if (JSON.stringify(item) === JSON.stringify(this.unitCascaderValue)) {
          contain = true;
          break;
        }
      }
      if (!contain) {
        this.history.push(this.unitCascaderValue);
        this.history = this.history.slice(-5);
        this.globalService.setFormCodeWbsHistory(
          this.data.data!.formCode!,
          this.history
        );
      }
    }
    this.data.onConfirm!(result);
  }
}

export class DialogSubFormChooseData {
  sectionItemId?: number;
  formCode?: string;
}

export class DialogSubFormChooseResult {
  sectionId?: number;
  sectionItemId?: number;
  formId?: number;
}
