import { Component, Input } from '@angular/core';
import copy from 'copy-text-to-clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from '../../components/alert-dialog/dialog-config';
import { FimpMeasuredValueFillTypeOptions } from '../../models/form/fimp-measured-value-fill-type.enum';
import { FimpMeasuredValueFormatOptions } from '../../models/form/fimp-measured-value-format.enum';
import { FormItemMeasuredProjectRefParamTypeOptions } from '../../models/form/form-item-measured-project-ref-param-type.enum';
import { FormItemMeasuredProjectSpOrAddv } from '../../models/form/form-item-measured-project-sp-or-addv.model';
import { FormItemMeasuredProjectSpadTypeOptions } from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProject } from '../../models/form/form-item-measured-project.model';
import { OmFormItemMeasuredProjectService } from '../../services/om-form-item-measured-project.service';

@Component({
  selector: 'app-dialog-form-zj-config',
  templateUrl: './dialog-form-zj-config.component.html',
  styleUrls: ['./dialog-form-zj-config.component.scss'],
})
export class DialogFormZjConfigComponent {
  @Input()
  data!: DialogConfig<FormItemMeasuredProject>;

  editing: { [name: string]: boolean } = {};

  record: FormItemMeasuredProject = new FormItemMeasuredProject();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  FimpMeasuredValueFormatOptions = FimpMeasuredValueFormatOptions;

  FimpMeasuredValueFillTypeOptions = FimpMeasuredValueFillTypeOptions;

  constructor(
    private omFormItemMeasuredProjectService: OmFormItemMeasuredProjectService,
    private nzMessage: NzMessageService
  ) {}

  ngOnInit() {
    this.record = this.handleDataBeforeInit(this.data.data!);
  }

  edit(name: string) {
    this.editing[name] = true;
  }

  onBlur() {
    this.editing = {};
  }

  private handleDataBeforeInit(
    response: FormItemMeasuredProject
  ): FormItemMeasuredProject {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProject;
    if (data.designValue) {
      try {
        const designValues = data.designValue.split('\n');
        data.designValue1 = !!designValues[0] ? designValues[0] : '';
        data.designValue2 = !!designValues[1] ? designValues[1] : '';
      } catch (e) {}
    }
    data.count = data.spadValues?.length ?? 1;
    data.spadSearchType = data.spadSearchType ?? '';
    data.defaultIndex = 0;
    if (data.spadValues && data.spadValues.length > 0) {
      for (let i = 0; i < data.spadValues.length; i++) {
        if (data.spadValues[i].defaulted) {
          data.defaultIndex = i;
          break;
        }
      }
    }
    return data;
  }

  onDefaultChange() {
    for (const item of this.record.spadValues ?? []) {
      item.defaulted = false;
    }
    this.record.spadValues![this.record.defaultIndex!].defaulted = true;
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  private handleDataBeforeConfirm(
    response: FormItemMeasuredProject
  ): FormItemMeasuredProject {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProject;
    // data.designValue = `${!!data.designValue1 ? data.designValue1 : ''}\n${
    //   !!data.designValue2 ? data.designValue2 : ''
    // }`;

    // 只针对单个spad值的情况
    // if (data.spadValues && data.spadValues.length === 1) {
    // for (const item of data.spadValues ?? []) {
    //   item.spadType = data.spadType;
    // }
    // }

    return data;
  }

  onCountChange() {
    if (this.isNaN(this.record.count!)) {
      this.nzMessage.error('输入的个数不是个数字');
      return;
    }
    if (!this.record.count) {
      // !! 卫丰原话：如果要添加和删除个数，直接在这里操作，不提供添加和删除的按钮。要是有插入和删除需求，他自己忍着，一个一个手动改。
      this.record.count = 0;
    }
    let items = this.record.spadValues
      ? JSON.parse(JSON.stringify(this.record.spadValues))
      : [];
    if (items.length !== +this.record.count) {
      if (items.length > +this.record.count) {
        items = items.slice(0, this.record.count);
      }
      if (items.length < +this.record.count) {
        for (let i = items.length; i < this.record.count; i++) {
          items.push(new FormItemMeasuredProjectSpOrAddv());
        }
      }
      this.record.spadValues = items;
    }
    if (this.record.count <= 1) {
      this.record.spadSearchType = '';
    }
  }

  get canConfirm() {
    if (
      !this.record.measuredValueFillType ||
      !this.record.measuredValueFormat
    ) {
      return false;
    }
    return true;
  }

  copy(str: string) {
    copy(str);
    this.nzMessage.info(`已复制${str}到剪贴板`);
  }

  onSpadTypeChange() {
    for (const item of this.record.spadValues ?? []) {
      item.spadType = this.record.spadType;
    }
  }

  onSpadChange(index: number) {
    const spadStr = this.record.spadValues![index].spadValue;
    this.record.spadValues![index].spadError = false;
    if (!spadStr) {
      this.record.spadValues![index].refParamName = '';
      this.record.spadValues![index].refParamSymbol = '';
      this.record.spadValues![index].refParamValue = '';
      this.record.spadValues![index].refParamVariable = '';
      this.record.spadValues![index].refParamType = undefined;
      return;
    }
    const test = spadStr
      .replace('MIN', '')
      .replace('MAX', '')
      .replace('≥', '')
      .replace('~', '+')
      .replace('～', '+')
      .replace('≤', '')
      .replace('±', '');
    // 获取参数名

    let sym = '';
    for (let i = 0; i < test.length; i++) {
      if (
        (test.charCodeAt(i) >= 65 && test.charCodeAt(i) <= 90) ||
        (test.charCodeAt(i) >= 97 && test.charCodeAt(i) <= 122)
      ) {
        sym += test[i];
      }
    }

    if (sym === '') {
      try {
        if (isNaN(eval(test))) {
          this.record.spadValues![index].spadError = true;
          this.record.spadValues![index].refParamName = '';
          this.record.spadValues![index].refParamSymbol = '';
          this.record.spadValues![index].refParamValue = '';
          this.record.spadValues![index].refParamVariable = '';
          this.record.spadValues![index].refParamType = undefined;
          return;
        }
      } catch (e) {
        this.record.spadValues![index].spadError = true;
        this.record.spadValues![index].refParamName = '';
        this.record.spadValues![index].refParamSymbol = '';
        this.record.spadValues![index].refParamValue = '';
        this.record.spadValues![index].refParamVariable = '';
        this.record.spadValues![index].refParamType = undefined;
        return;
      }
    } else {
      if (sym.length > 1) {
        this.record.spadValues![index].spadError = true;
        this.record.spadValues![index].refParamName = '';
        this.record.spadValues![index].refParamSymbol = '';
        this.record.spadValues![index].refParamValue = '';
        this.record.spadValues![index].refParamVariable = '';
        this.record.spadValues![index].refParamType = undefined;
        return;
      }
      this.record.spadValues![index].refParamSymbol = sym;
      try {
        if (isNaN(eval(test.replace(sym, '100')))) {
          this.record.spadValues![index].spadError = true;
          this.record.spadValues![index].refParamName = '';
          this.record.spadValues![index].refParamSymbol = '';
          this.record.spadValues![index].refParamValue = '';
          this.record.spadValues![index].refParamVariable = '';
          this.record.spadValues![index].refParamType = undefined;
          return;
        }
      } catch (e) {
        this.record.spadValues![index].spadError = true;
        this.record.spadValues![index].refParamName = '';
        this.record.spadValues![index].refParamSymbol = '';
        this.record.spadValues![index].refParamValue = '';
        this.record.spadValues![index].refParamVariable = '';
        this.record.spadValues![index].refParamType = undefined;
        return;
      }
    }
  }

  private submit() {
    const data = this.handleDataBeforeConfirm(this.record);
    this.omFormItemMeasuredProjectService.update(data).subscribe((response) => {
      this.data.onConfirm!(data);
    });
  }
}
