<div class="dialog">
	<div class="dialog-header">【{{record.keywords}}】- 实测值
	</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					规定值或允许偏差
				</div>
				<div class="column background-module" [style.width]="200 + 'px'" *ngIf="!isZS">
					<input class="color-primary require" type="text" [placeholder]="'输入规定值或允许偏差'" [(ngModel)]="biasStr"
						nz-dropdown [nzDropdownMenu]="record.editable ? biasSelect : null" [nzPlacement]="'bottomLeft'"
						(blur)="onBiasChange()" [disabled]="!record.editable">
					<nz-dropdown-menu #biasSelect="nzDropdownMenu">
						<ul nz-menu>
							<li nz-menu-item *ngFor="let item of record.spadValues; let index = index"
								(click)="selectBias(index)">
								{{!!item.spadValue ? item.spadValue : item.spadDesc}}{{item.keywords ? '(' +
								item.keywords
								+ ')' : ''}}
							</li>
						</ul>
					</nz-dropdown-menu>
				</div>
				<div class="column background-module text-left" [style.width]=" 200 + 'px'" *ngIf=" isZS">
					≥Ⅱ类
					<!-- <nz-select class="require" [(ngModel)]="biasStr" [nzPlaceHolder]="'选择类型'" nzAllowClear
						(ngModelChange)="onBiasChange()" [disabled]="!record.editable">
						<nz-option *ngFor="let item of ZS" [nzLabel]="item.label" [nzValue]="item.value">
						</nz-option>
					</nz-select> -->
				</div>
				<!-- <div class="column background-module" [style.width]="200 + 'px'" *ngIf="special">
					<nz-select class="require" [(ngModel)]="biasStr" [nzPlaceHolder]="'请选择'" nzAllowClear
						(ngModelChange)="onBiasChange()" *ngIf="special">
						<nz-option *ngFor="let item of specialOptions" [nzLabel]="item.label" [nzValue]="item.value">
						</nz-option>
					</nz-select>
				</div> -->
				<div class="column background-module color-light text-left" [style.width]="400 + 'px'"
					*ngIf="!currentSpad.refParamSymbol">
					选择推荐值(若有)，或用户自填
				</div>
				<div class="column background-page" [style.width]="100 + 'px'" *ngIf="currentSpad.refParamSymbol">
					{{currentSpad.refParamSymbol}} {{currentSpad.refParamName}}
				</div>
				<div class="column background-module" [style.width]="100 + 'px'" *ngIf="currentSpad.refParamSymbol">
					<input class="color-primary" type="text" appDigitalFormat [(ngModel)]="currentSpad.refParamValue"
						(blur)="onRefValueChange()" [disabled]="!record.editable">
				</div>
				<div class="column background-page text-right" [style.width]="100 + 'px'"
					*ngIf="currentSpad.refParamSymbol">
					计算所得
				</div>
				<div class="column background-module" [style.width]="100 + 'px'" *ngIf="currentSpad.refParamSymbol">
					{{currentSpad.calcDisplay}}
				</div>
				<div class="column background-module" [style.width]="100 + 'px'">
					点击复制符号
				</div>
				<div class="column background-module" [style.width]="50 + 'px'" (click)="copy('±')">
					±
				</div>
				<div class="column background-module" [style.width]="50 + 'px'" (click)="copy('≥')">
					≥
				</div>
				<div class="column background-module" [style.width]="50 + 'px'" (click)="copy('≤')">
					≤
				</div>
				<div class="column background-module" [style.width]="50 + 'px'" (click)="copy('~')">
					~
				</div>
			</div>
			<div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					实测值填写
				</div>
				<div class="column background-module color-light" [style.width]="200 + 'px'">
					<nz-select class="require" [(ngModel)]="record.measuredValueFormat" [nzPlaceHolder]="'选择类型'"
						(ngModelChange)="measuredValueFormatChange()" [disabled]="!record.editable">
						<nz-option *ngFor="let item of FimpMeasuredValueFormatOptions" [nzLabel]="item.label"
							[nzValue]="item.value">
						</nz-option>
					</nz-select>
				</div>
				<div class="column background-module color-light text-left"
					*ngIf="!record.measuredValueFormat || record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC || record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT"
					[style.width]="400 + 'px'">
					请在以下实测值表格中填写
				</div>
				<div class="column background-module color-primary"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT"
					[style.width]="350 + 'px'">
					<input type="text" [(ngModel)]="record.refReportName" [placeholder]="'输入报告名称'"
						[disabled]="!record.editable">
				</div>
				<div class="column background-module color-primary"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 || record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103"
					[style.width]="400 + 'px'">
					<select [(ngModel)]="record.refWorkReportId" (ngModelChange)="onWorkReportChange()"
						[disabled]="!record.editable">
						<option value="undefined" [disabled]="true">请选择表单</option>
						<option *ngFor="let item of workReportList" [value]="item.workReportId">
							{{item.formCode}}{{workReportList.length > 1 ? '[' + item.formCopiesOrder + ']' : ''}}
							{{item.measuredDate ? '(' :''}}{{ item.measuredDate | date:'yyyy-MM-dd'}}{{item.measuredDate
							? ')' :''}}
						</option>
					</select>
				</div>
				<div class="column background-module" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat !== FimpMeasuredValueFormat.SEE_REPORT">
					实测值: {{totalCount}}个
				</div>
				<div class="column background-module" [style.width]="50 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT">
					合格率
				</div>
				<div class="column background-module" [style.width]="50 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT">
					<input type="text" [(ngModel)]="record.qaRate" [disabled]="!record.editable">
				</div>
				<div class="column background-module" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC">
					合格率: {{passRate}}%
				</div>
				<div class="column background-module" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 || record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103">
					合格率: {{record.qaRate}}
				</div>
				<div class="column background-module" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC || record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT ">
					<label *ngIf="currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION"
						[disabled]="!record.editable" nz-checkbox [(ngModel)]="showDe">显示偏差</label>
				</div>
				<div class="column background-module" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 || record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103">
					平均值: {{record.averageValue ?? ''}}
				</div>
				<div class="column background-module color-light" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT">
					<button class="color-primary" nz-button nzType="text">
						+上传报告
						<input type="file" (change)="upload($event)" accept=".pdf" *ngIf="record.editable">
					</button>
				</div>
				<div class="column background-module color-light" [style.width]="100 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT">
					<button nz-button nzType="text" (click)="clearReport()"
						[disabled]="!record.refReportFileId || !record.editable">
						删除报告
					</button>
				</div>
				<div class="column background-module color-light" [style.width]="150 + 'px'"
					*ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT">
					<label nz-checkbox [(ngModel)]="record.refReportMerge" [disabled]="!record.editable">报告合入主表</label>
				</div>
			</div>
		</div>

		<div *ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC">
			<div class="grid" *ngFor="let info of detectionInfoList; let groupIndex = index;">
				<div class="row">
					<div class="column background-page" [style.width]="120 + 'px'">
						实测值
					</div>
					<div class="column background-primary color-white clickable" [style.width]="40 + 'px'"
						(click)="addGroup()">
						+
					</div>
					<div class="column background-page color-grey" [style.width]="160 + 'px'"
						*ngIf="detectionInfoList.length === 1">
						多部位或多设计值按+
					</div>
					<div class="column background-page" [style.width]="60 + 'px'" *ngIf="detectionInfoList.length > 1">
						对应部位
					</div>
					<div class="column background-page" [style.width]="100 + 'px'" *ngIf="detectionInfoList.length > 1">
						<input class="color-primary" type="text" [placeholder]="'输入部位名称'" [(ngModel)]="info.part"
							[disabled]="!record.editable">
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						<span class="color-danger"
							*ngIf="currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION ||
						currentSpad.spadType === FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE || currentSpad.spadType === FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE">
							*
						</span>
						设计值:
					</div>
					<div class="column background-page" [style.width]="100 + 'px'">
						<input class="color-primary" type="text" [placeholder]="'输入设计值'" [(ngModel)]="info.designValue"
							[disabled]="!record.editable" (blur)="onRefValueChange()" *ngIf="!special && !isZS">
						<select [(ngModel)]="info.designValue" *ngIf="special" (ngModelChange)="onRefValueChange()"
							[disabled]="!record.editable">
							<option [value]="''" disabled>请选择</option>
							<option [value]="item.value" *ngFor="let item of specialOptions">{{item.label}}</option>
						</select>
						<select [(ngModel)]="info.designValue" *ngIf="isZS" (ngModelChange)="onRefValueChange()"
							[disabled]="!record.editable">
							<option [value]="''" disabled>请选择</option>
							<option [value]="item.value" *ngFor="let item of ZSV">{{item.label}}</option>
						</select>
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						平均值(实测值)
					</div>
					<div class="column background-module" [style.width]="100 + 'px'">
						{{info.exp ? '-' : isZS ? '-' : info.avg}}
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						代表值：
					</div>
					<div class="column background-page" [style.width]="100 + 'px'">
						<input class="color-primary" type="text" [(ngModel)]="info.exp" [disabled]="!record.editable">
					</div>
					<div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
						(click)="clearGroup(groupIndex)" *ngIf="detectionInfoList.length === 1">
						× 清空数值
					</div>
					<div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
						(click)="removeGroup(groupIndex)" *ngIf="detectionInfoList.length > 1">
						- 删除本组
					</div>
				</div>
				<div class="row">
					<div class="column background-module" [style.width]="20 + 'px'" [style.height]="20 + 'px'">

					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						1
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						2
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						3
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						4
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						5
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						6
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						7
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						8
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						9
					</div>
					<div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
						10
					</div>
				</div>
				<div class="row real{{groupIndex}}" *ngFor="let valrow of info.realValDisplayList; let rowIdx = index;">
					<div class="column background-module" [style.width]="20 + 'px'">
						{{rowIdx + 1}}
					</div>
					<div class="column background-module" *ngFor="let item of valrow; let colIdx = index;"
						[style.width]="100 + 'px'">
						<input *ngIf="!isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}"
							type="text" [(ngModel)]="item.value"
							(ngModelChange)="onRealChange(groupIndex, rowIdx, colIdx)"
							[style.height]="showDe ? '50%' : '100%'" (blur)="onRealBlur(groupIndex, rowIdx, colIdx)"
							(keydown)="onRealInputKeyDown($event, groupIndex , rowIdx, colIdx)"
							[disabled]="!record.editable">
						<select *ngIf="isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}"
							[(ngModel)]="item.value" (ngModelChange)="onRealBlur(groupIndex, rowIdx, colIdx)"
							[disabled]="!record.editable">
							<option [value]="''"></option>
							<option [value]="item.value" *ngFor="let item of ZSV">{{item.label}}</option>
						</select>
						<div class="cell center background-module {{item.hasError ? 'color-danger' : ''}}"
							*ngIf="showDe">
							{{item.value !== undefined && item.value !== '' && info.designValue !== undefined &&
							info.designValue !== '' ? ((+item.value > +info.designValue) ? '+' : '' ) +
							(+item.value -
							+info.designValue).toFixed(precision) : ''}}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT">
			<div class="grid" *ngFor="let info of detectionInfoList; let groupIndex = index;">
				<div class="row">
					<div class="column background-page" [style.width]="120 + 'px'">
						实测值
					</div>
					<div class="column background-primary color-white clickable" [style.width]="40 + 'px'"
						(click)="addGroup()">
						+
					</div>
					<div class="column background-page color-grey" [style.width]="160 + 'px'"
						*ngIf="detectionInfoList.length === 1">
						多部位或多设计值按+
					</div>
					<div class="column background-page" [style.width]="60 + 'px'" *ngIf="detectionInfoList.length > 1">
						对应部位
					</div>
					<div class="column background-page" [style.width]="100 + 'px'" *ngIf="detectionInfoList.length > 1">
						<input class="color-primary" type="text" [placeholder]="'输入部位名称'" [(ngModel)]="info.part"
							[disabled]="!record.editable">
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						设计值:
					</div>
					<div class="column background-page" [style.width]="100 + 'px'">
						<input class="color-primary" type="text" [placeholder]="'输入设计值'" [(ngModel)]="info.designValue"
							(blur)="onRefValueChange()" [disabled]="!record.editable">
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						平均值(实测值)
					</div>
					<div class="column background-module" [style.width]="100 + 'px'">
						<input *ngIf="!info.exp" class="color-primary" type="text" [(ngModel)]="info.avg"
							[disabled]="!record.editable">
					</div>
					<div class="column background-page text-right" [style.width]="100 + 'px'">
						代表值：
					</div>
					<div class="column background-page" [style.width]="100 + 'px'">
						<input class="color-primary" type="text" [(ngModel)]="info.exp">
					</div>
					<div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
						(click)="clearGroup(groupIndex)" *ngIf="detectionInfoList.length === 1">
						× 清空数值
					</div>
					<div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
						(click)="removeGroup(groupIndex)" *ngIf="detectionInfoList.length > 1">
						- 删除本组
					</div>
				</div>
				<div class="row">
					<div class="column background-module" [style.width]="120 + 'px'">
						实测值数据
					</div>
					<div class="column background-module" [style.width]="900 + 'px'">
						<input type="text" [(ngModel)]="info.realValTxt" [placeholder]="'输入实测值（可以为文本；多个实测值可以空格间隔）'"
							[disabled]="!record.editable">
					</div>
				</div>
				<div class="row">
					<div class="column background-module" [style.width]="120 + 'px'">
						偏差值数据
					</div>
					<div class="column background-module" [style.width]="900 + 'px'">
						<input type="text" [(ngModel)]="info.biasValTxt" [placeholder]="'输入偏差值（有则输入；多个偏差值可以空格间隔）'"
							[disabled]="!record.editable">
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101">
			<div class="grid data">
				<div class="row">
					<div class="column background-page seprarate" [style.width]="150 + 'px'">
						测量点位
					</div>
					<div class="column background-page" [style.width]="120 + 'px'">
						X-设计坐标(m)
					</div>
					<div class="column background-page" [style.width]="120 + 'px'">
						X-实测坐标(m)
					</div>
					<div class="column background-page seprarate" [style.width]="120 + 'px'">
						X-偏位(mm)
					</div>
					<div class="column background-page" [style.width]="120 + 'px'">
						Y-设计坐标(m)
					</div>
					<div class="column background-page" [style.width]="120 + 'px'">
						Y-实测坐标(m)
					</div>
					<div class="column background-page seprarate" [style.width]="120 + 'px'">
						Y-偏位(mm)
					</div>
					<div class="column background-page" [style.width]="150 + 'px'">
						偏位
					</div>
				</div>
				<div class="row" *ngFor="let info of workReportMeasuredValueInfos">
					<div class="column background-module" [style.width]="150 + 'px'">
						{{info.value}}
					</div>
					<div class="column background-module" [style.width]="120 + 'px'">
						{{info['designCoordinatex']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info.deviationValue && !isNaN(+info.deviationValue!) && (+info.deviationValue! >detectionMeasureInfo.biasRealValMax! || +info.deviationValue! < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="120 + 'px'">
						{{info['measuredCoordinatex']}}
					</div>
					<div class="column background-module {{!detectionMeasureInfo.biasError && !!info.deviationValue && !isNaN(+info.deviationValue!) && (+info.deviationValue! >detectionMeasureInfo.biasRealValMax! || +info.deviationValue! < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="120 + 'px'">
						{{info['deviationCoordinatex']}}
					</div>
					<div class="column background-module" [style.width]="120 + 'px'">
						{{info['designCoordinatey']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info.deviationValue && !isNaN(+info.deviationValue!) && (+info.deviationValue! >detectionMeasureInfo.biasRealValMax! || +info.deviationValue! < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="120 + 'px'">
						{{info['measuredCoordinatey']}}
					</div>
					<div class="column background-module {{!detectionMeasureInfo.biasError && !!info.deviationValue && !isNaN(+info.deviationValue!) && (+info.deviationValue! >detectionMeasureInfo.biasRealValMax! || +info.deviationValue! < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="120 + 'px'">
						{{info['deviationCoordinatey']}}
					</div>
					<div class="column background-module {{!detectionMeasureInfo.biasError && !!info.deviationValue && !isNaN(+info.deviationValue!) && (+info.deviationValue! >detectionMeasureInfo.biasRealValMax! || +info.deviationValue! < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="150 + 'px'">
						{{info.deviationValue}}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103">
			<div class="grid data">
				<div class="row">
					<div class="column background-page seprarate" [style.width]="100 + 'px'">
						桩号、部位
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						设计高程1<br />(m)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						实测高程1<br />(m)
					</div>
					<div class="column background-page seprarate" [style.width]="70 + 'px'">
						高差1<br />(mm)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						设计高程2<br />(m)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						实测高程2<br />(m)
					</div>
					<div class="column background-page seprarate" [style.width]="70 + 'px'">
						高差2<br />(mm)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						设计高程3<br />(m)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						实测高程3<br />(m)
					</div>
					<div class="column background-page seprarate" [style.width]="70 + 'px'">
						高差3<br />(mm)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						设计高程4<br />(m)
					</div>
					<div class="column background-page" [style.width]="80 + 'px'">
						实测高程4<br />(m)
					</div>
					<div class="column background-page" [style.width]="70 + 'px'">
						高差4<br />(mm)
					</div>
				</div>
				<div class="row" *ngFor="let info of workReportMeasuredValueInfos">
					<div class="column background-module seprarate" [style.width]="100 + 'px'">
						{{info.value}}
					</div>
					<div class="column background-module" [style.width]="80 + 'px'">
						{{info['designElevation1']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info['deviationElevation1'] && !isNaN(+info['deviationElevation1']) && (+info['deviationElevation1'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation1'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="80 + 'px'">
						{{info['measuredElevation1']}}
					</div>
					<div class="column background-module seprarate {{!detectionMeasureInfo.biasError && !!info['deviationElevation1'] && !isNaN(+info['deviationElevation1']) && (+info['deviationElevation1'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation1'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="70 + 'px'">
						{{info['deviationElevation1']}}
					</div>
					<div class="column background-module" [style.width]="80 + 'px'">
						{{info['designElevation2']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info['deviationElevation2'] && !isNaN(+info['deviationElevation2']) && (+info['deviationElevation2'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation2'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="80 + 'px'">
						{{info['measuredElevation2']}}
					</div>
					<div class="column background-module seprarate {{!detectionMeasureInfo.biasError && !!info['deviationElevation2'] && !isNaN(+info['deviationElevation2']) && (+info['deviationElevation2'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation2'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="70 + 'px'">
						{{info['deviationElevation2']}}
					</div>
					<div class="column background-module" [style.width]="80 + 'px'">
						{{info['designElevation3']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info['deviationElevation3'] && !isNaN(+info['deviationElevation3']) && (+info['deviationElevation3'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation3'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="80 + 'px'">
						{{info['measuredElevation3']}}
					</div>
					<div class="column background-module seprarate {{!detectionMeasureInfo.biasError && !!info['deviationElevation3'] && !isNaN(+info['deviationElevation3']) && (+info['deviationElevation3'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation3'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="70 + 'px'">
						{{info['deviationElevation3']}}
					</div>
					<div class="column background-module" [style.width]="80 + 'px'">
						{{info['designElevation4']}}
					</div>
					<div class="column background-module color-primary {{!detectionMeasureInfo.biasError && !!info['deviationElevation4'] && !isNaN(+info['deviationElevation4']) && (+info['deviationElevation4'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation4'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="80 + 'px'">
						{{info['measuredElevation4']}}
					</div>
					<div class="column background-module {{!detectionMeasureInfo.biasError && !!info['deviationElevation4'] && !isNaN(+info['deviationElevation4']) && (+info['deviationElevation4'] >detectionMeasureInfo.biasRealValMax! || +info['deviationElevation4'] < detectionMeasureInfo.biasRealValMin!) ? 'color-danger' : ''}}"
						[style.width]="70 + 'px'">
						{{info['deviationElevation4']}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<!-- <span class="color-light" *ngIf="record.editable">实测值（个数：{{totalCount}}；合格率：{{passRate}}%）</span> -->
		<span class="color-danger" *ngIf="!record.editable">{{record.message}}</span>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>