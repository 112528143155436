import { OrderType } from '../../providers/request/order-type.enum';
import { SearchRequest } from '../../providers/request/search-request.model';
import { FormItemType } from './form-item-type.enum';
import { FormItemWidgetType } from './form-item-widget-type.enum';

export class FormItemSearchRequest extends SearchRequest {
  formId?: number;
  tableId?: number;
  type?: FormItemType;
  address?: string;
  widget?: FormItemWidgetType;
  format?: string;
  dataSource?: string;
  musted?: boolean = undefined;
  defaultValue?: string;
  hint?: string;
  formula?: string;
  rowExtend?: string;
  needHistory?: boolean;
  historyDimension?: string;
  expanded?: boolean;
  comment?: string;
  rowColOrder?: OrderType = OrderType.ASC;
  colRowOrder?: OrderType = OrderType.ASC;
}
