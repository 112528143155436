import { Component, Input } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { FilePreviewTypeListMap } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import { TableColumnOption } from 'src/app/modules/shared/components/table-pro/table.config';
import { FileType } from 'src/app/modules/shared/models/form/file-type.enum';
import { FormHeaderFormat } from 'src/app/modules/shared/models/form/form-header-format.enum';
import { FormHeaderListRequest } from 'src/app/modules/shared/models/form/form-header-list-request.model';
import { FormHeaderSearch } from 'src/app/modules/shared/models/form/form-header-search.enum';
import { FormHeaderType } from 'src/app/modules/shared/models/form/form-header-type.enum';
import { FormHeader } from 'src/app/modules/shared/models/form/form-header.model';
import { FormItemType } from 'src/app/modules/shared/models/form/form-item-type.enum';
import { FormItemWidgetType } from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReportStatus } from 'src/app/modules/shared/models/form/work-report-status.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { ProjectSection } from 'src/app/modules/shared/models/project/project-section.model';
import { OrganizationListRequest } from 'src/app/modules/shared/models/staff/organization-list-request.model';
import { OrganizationType } from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { OmFormHeaderService } from 'src/app/modules/shared/services/om-form-header.service';
import { ProjectSectionService } from 'src/app/modules/shared/services/project-section.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-menu-form-fill-dialog',
  templateUrl: './custom-menu-form-fill-dialog.component.html',
  styleUrls: ['./custom-menu-form-fill-dialog.component.scss'],
})
export class CustomMenuFormFillDialogComponent {
  @Input()
  data!: DialogConfig<WorkReport>;

  editing: { [name: string]: boolean } = {};

  record: WorkReport = new WorkReport();

  fileUrls: string[] = [];

  sectionIdList: number[] = [];

  sectionList: ProjectSection[] = [];

  optionMap: { [key: string]: TableColumnOption[] } = {};

  headerList: FormHeader[] = [];

  headerMap: { [key: string]: FormHeader } = {};

  FormHeaderFormat = FormHeaderFormat;

  supOrgNameList: string[] = [];
  consOrgNameList: string[] = [];

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FileType = FileType;
  FormHeaderSearch = FormHeaderSearch;
  // transformableImageElementRef!: TransformableElement;

  currentFileId = '';

  currentFileType = FileType.PDF;

  environment = environment;

  height = 0;
  width = 0;
  // index = 0;

  pdfZoom = 1;
  pdfPages = 1;
  pdfTotal = 0;

  WorkReportDocType = WorkReportDocType;

  loading: any;

  freeHeaderFormItemIds: number[] = [];
  freeHeaderFormItemMap: { [key: string]: string } = {};

  requireMap: { [key: string]: boolean } = {};

  oneday = 24 * 60 * 60 * 1000;

  constructor(
    private globalService: GlobalService,
    private omFormHeaderService: OmFormHeaderService,
    private workReportService: WorkReportService,
    private projectSectionService: ProjectSectionService,
    private staffOrganizationService: StaffOrganizationService
  ) {}

  private prepare() {
    zip(
      this.projectSectionService.findAll({
        projectId: this.globalService.projectId,
      }),
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
      }),

      this.omFormHeaderService.findAll<FormHeaderListRequest>({
        formId: this.record.formId,
      })
    ).subscribe(([sectionList, orgList, headers]) => {
      this.sectionList = sectionList ?? [];
      for (const org of orgList) {
        if (org.type === OrganizationType.SUPERVISE) {
          this.supOrgNameList.push(org.name!);
        }
        if (org.type === OrganizationType.CONSTRUCTION) {
          this.consOrgNameList.push(org.name!);
        }
      }

      const obj$: Observable<string[]>[] = [];
      const optKeys: string[] = [];
      const headersMap: { [key: string]: FormHeader } = {};
      const headerList: FormHeader[] = [];
      console.log(headers);
      for (const header of headers) {
        headersMap[header.fieldName!] = header;
        if (header.type === FormHeaderType.FREE && !header.hidden) {
          this.freeHeaderFormItemIds.push(header.formItemId!);
          this.freeHeaderFormItemMap[header.formItemId!] = header.fieldName!;
        }
        if (
          header.hidden ||
          !header.headerName ||
          header.fieldName === 'sectionId' ||
          header.fieldName === 'constructionOrgName' ||
          header.fieldName === 'supervisionOrgName'
        ) {
          continue;
        }
        headerList.push(header);
        if (header.search === FormHeaderSearch.ENUM) {
          obj$.push(
            this.workReportService.listEnumValues({
              fieldName: header.fieldName!,
              menuId: this.record.menuId!,
              projectId: this.globalService.projectId,
            })
          );
          optKeys.push(header.fieldName!);
        }
        if (!!header.fixedParamValue) {
          this.record[header.fieldName!] = header.fixedParamValue;
        }
      }
      this.headerList = headerList;
      this.headerMap = headersMap;
      console.log(this.freeHeaderFormItemIds);
      for (const item of this.record.form?.formItems ?? []) {
        if (
          ((item.musted && item.type === FormItemType.VARIABLES) ||
            item.type === FormItemType.CONSTANT) &&
          this.freeHeaderFormItemIds.includes(item.id!)
        ) {
          this.requireMap[item.id!] = true;
        }
      }
      console.log(this.requireMap);

      zip(...obj$).subscribe((res: string[][]) => {
        for (let i = 0; i < res.length; i++) {
          const opts: TableColumnOption[] = [];
          for (const item of res[i]) {
            opts.push({
              label: item,
              value: item,
            });
          }
          this.optionMap[optKeys[i]] = opts;
        }
      });
      this.autoFill();
    });
  }

  ngOnInit() {
    this.record = this.handleDataBeforeInit(this.data.data!);
    if (this.record.status === WorkReportStatus.FINISH) {
      this.workReportService
        .findById(this.record.id!)
        .subscribe((response: WorkReport) => {
          this.record = response;
          console.log(response);
          this.handleFiles();
          this.prepare();
        });
    } else {
      this.handleFiles();
      this.prepare();
    }
  }

  private autoFill() {
    // 如果我是监理单位，那么我只能选择监理单位
    // 如果我是施工单位，那么我选择施工单位，然后选择标段，最后选择监理单位
    if (this.globalService.orgInfo.type === OrganizationType.SUPERVISE) {
      this.record.supervisionOrgName = this.globalService.orgInfo.name;
    }
    if (this.globalService.orgInfo.type === OrganizationType.CONSTRUCTION) {
      this.record.constructionOrgName = this.globalService.orgInfo.name;
      this.record.sectionId =
        this.globalService.orgInfo.projectSections &&
        this.globalService.orgInfo.projectSections[0].sectionId
          ? this.globalService.orgInfo.projectSections[0].sectionId
          : undefined;
      if (this.record.sectionId) {
        this.staffOrganizationService
          .findAll<OrganizationListRequest>({
            projectId: this.globalService.projectId,
            projectSections: this.globalService.orgInfo.projectSections,
            type: OrganizationType.SUPERVISE,
          })
          .subscribe((orgList: Organization[]) => {
            if (orgList.length > 0) {
              this.record.supervisionOrgName = orgList[0].name;
            }
          });
      }
    }
    const cache = this.globalService.getFormCodeFillHistory(this.record.code!);
    if (!!cache) {
      for (const header of this.headerList) {
        if (
          header.hidden ||
          !header.headerName ||
          header.fieldName === 'sectionId' ||
          header.fieldName === 'constructionOrgName' ||
          header.fieldName === 'supervisionOrgName' ||
          header.fieldName === 'sn'
        ) {
          continue;
        }
        if (
          header.format === FormHeaderFormat.DATE &&
          !this.record[header.fieldName!] &&
          this.record[header.fieldName!] !== '-' &&
          cache[header.fieldName!] !== '-' &&
          !!cache[header.fieldName!]
        ) {
          try {
            const d = new Date(cache[header.fieldName!]);
            this.record[header.fieldName!] = new Date(
              d.getTime() + this.oneday
            );
          } catch (e) {}
        }
        if (
          header.format !== FormHeaderFormat.DATE &&
          !!cache[header.fieldName!] &&
          this.record[header.fieldName!] !== '-' &&
          !this.record[header.fieldName!]
        ) {
          this.record[header.fieldName!] = cache[header.fieldName!];
        }
      }
    }
    for (const table of this.record.form?.formData ?? []) {
      for (const row of table.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (
            !!cell.formItem?.id &&
            this.freeHeaderFormItemIds.includes(cell.formItem.id) &&
            !!this.freeHeaderFormItemMap[cell.formItem.id] &&
            !!cell.value &&
            cell.formItem.widget !== FormItemWidgetType.DATE
          ) {
            this.record[this.freeHeaderFormItemMap[cell.formItem.id]] =
              cell.value;
          }
        }
      }
    }
  }

  private handleFiles() {
    try {
      if (this.record.pdfFiles) {
        this.record.pdfFilesList = JSON.parse(this.record.pdfFiles);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.record.signHalfFiles) {
        this.record.signHalfFilesList = JSON.parse(this.record.signHalfFiles);
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.record.signFiles) {
        this.record.signFilesList = JSON.parse(this.record.signFiles);
      }
    } catch (e) {
      console.log(e);
    }
    if (this.record.docType === WorkReportDocType.PDF_SIGNED) {
      this.record.docPdfId = this.record.signFilesList![0];
    } else {
      if (this.record.signFilesList && this.record.signFilesList.length > 0) {
        this.record.docPdfId = this.record.signFilesList![0];
      } else if (
        this.record.signHalfFilesList &&
        this.record.signHalfFilesList.length > 0
      ) {
        this.record.docPdfId = this.record.signHalfFilesList![0];
      } else {
        this.record.docPdfId = this.record.pdfFilesList![0];
      }
    }
    this.currentFileId = this.record.docPdfId ?? '';
  }

  chooseMain() {
    this.currentFileId = this.record.docPdfId!;
    this.currentFileType = FileType.PDF;
  }

  chooseAttach(index: number) {
    console.log(index);
    this.currentFileId = this.record.attachs![index].fileId!;
    this.currentFileType = this.record.attachs![index].fileType!;
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', () => {
      this.resize();
    });
    setTimeout(() => {
      this.resize();
    }, 100);
  }

  resize() {
    this.height = document.body.clientHeight;
    this.width =
      this.record.attachs && this.record.attachs.length > 0
        ? document.body.clientWidth - 910
        : document.body.clientWidth - 610;
  }

  onPageLoad(event: any) {
    console.log(event);
    this.loading.loadingInstance.close();
    this.pdfTotal = event.source.pdfDocument._pdfInfo.numPages;
    this.resize();
  }

  prePage() {
    if (this.pdfPages > 1) {
      this.pdfPages--;
    }
  }

  nextPage() {
    if (this.pdfPages < this.pdfTotal) {
      this.pdfPages++;
    }
  }

  originSize() {
    this.pdfZoom = 1;
  }

  zoomIn() {
    this.pdfZoom += 0.1;
    if (this.pdfZoom > 2) {
      this.pdfZoom = 2;
    }
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
    if (this.pdfZoom < 0.5) {
      this.pdfZoom = 0.5;
    }
  }

  onBlur() {
    this.editing = {};
  }

  private handleDataBeforeInit(response: WorkReport): WorkReport {
    const data = JSON.parse(JSON.stringify(response)) as WorkReport;
    return data;
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    if (this.record.status !== WorkReportStatus.FINISH) {
      const result = this.handleDataBeforeConfirm(this.record);
      this.data.onConfirm!(result);
    } else {
      this.submit();
    }
  }

  private handleDataBeforeConfirm(response: WorkReport): WorkReport {
    const data = JSON.parse(JSON.stringify(response)) as WorkReport;
    for (const header of this.headerList) {
      if (header.hidden) {
        continue;
      }
      if (
        header.format === FormHeaderFormat.DATE &&
        data[header.fieldName!] !== '-' &&
        !!data[header.fieldName!]
      ) {
        const d = new Date(data[header.fieldName!]);
        data[header.fieldName!] = `${d.getFullYear()}-${(d.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
      }
      if (
        header.fieldName === '归属年月' &&
        data[header.fieldName!] !== '-' &&
        !!data[header.fieldName!]
      ) {
        const d = new Date(data[header.fieldName!]);
        data[header.fieldName!] = `${d.getFullYear()}${(d.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`;
      }
    }
    for (const table of data.form?.formData ?? []) {
      for (const row of table.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (cell.formItem?.widget === FormItemWidgetType.CODE && !!data.sn) {
            cell.value = data.sn ?? '';
          }
        }
      }
    }
    const cache: any = {};
    for (const header of this.headerList) {
      if (
        header.hidden ||
        !header.headerName ||
        header.fieldName === 'sectionId' ||
        header.fieldName === 'constructionOrgName' ||
        header.fieldName === 'supervisionOrgName' ||
        header.fieldName === 'sn'
      ) {
        continue;
      }
      if (!!data[header.fieldName!]) {
        cache[header.fieldName!] = data[header.fieldName!];
      }
    }
    this.globalService.setFormCodeFillHistory(data.code!, cache);
    return data;
  }

  get canConfirm() {
    for (const header of this.headerList) {
      if (
        !this.record[header.fieldName!] &&
        this.requireMap[header.formItemId!]
      ) {
        return false;
      }
    }
    return true;
  }

  private submit() {
    const record = this.handleDataBeforeConfirm(this.record);
    this.workReportService
      .addingContent(record)
      .subscribe((response: boolean) => {
        if (response) {
          this.data.onConfirm!(record);
        }
      });
  }
}
