import { ProjectItemTypeMap } from './../../models/project/project-item-type.enum';
import { Component, Input } from '@angular/core';
import { DialogConfig } from '../alert-dialog/dialog-config';
import { ProjectItemType } from '../../models/project/project-item-type.enum';
import { GlobalService } from '../../providers/global.service';

@Component({
  selector: 'app-dialog-wbs-choose',
  templateUrl: './dialog-wbs-choose.component.html',
  styleUrls: ['./dialog-wbs-choose.component.scss'],
})
export class DialogWbsChooseComponent {
  // TODO 增加历史记录
  @Input()
  data!: DialogConfig<WbsChooseConfig>;

  editing: { [name: string]: boolean } = {};

  unitCascaderValue: any[] = [];

  type: ProjectItemType = ProjectItemType.SECTION;

  ProjectItemTypeMap = ProjectItemTypeMap;

  history: any[][] = [];

  onUnitCascaderValueChange(event: any[]) {
    this.unitCascaderValue = event;
  }

  constructor(private globalService: GlobalService) {} // private bankService: BankService

  ngOnInit() {
    if (this.data.data) {
      this.type = this.data.data.projectItemType ?? ProjectItemType.SECTION;
      this.history =
        this.globalService.getFormCodeWbsHistory(this.data.data.formCode!) ??
        [];
    }
  }

  onBlur() {
    this.editing = {};
  }

  // private handleDataBeforeInit(response: Bank): Bank {
  //   const data = JSON.parse(JSON.stringify(response)) as Bank;
  //   if (!data.bankLogoUrl) {
  //     data.bankLogoUrlList = [];
  //   } else {
  //     data.bankLogoUrlList = JSON.parse(data.bankLogoUrl);
  //   }

  //   return data;
  // }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  chooseHistory(index: number) {
    this.unitCascaderValue = JSON.parse(JSON.stringify(this.history[index]));
  }

  // private handleDataBeforeConfirm(bank: Bank): Bank {
  //   const data = JSON.parse(JSON.stringify(bank)) as Bank;
  //   data.id = undefined;
  //   data.bankLogoUrl = JSON.stringify(data.bankLogoUrlList);
  //   return data;
  // }

  get canConfirm() {
    return this.unitCascaderValue.length > 0;
  }

  private submit() {
    let contain = false;
    for (const item of this.history) {
      if (JSON.stringify(item) === JSON.stringify(this.unitCascaderValue)) {
        contain = true;
        break;
      }
    }
    if (!contain) {
      this.history.push(this.unitCascaderValue);
      this.history = this.history.slice(-5);
      this.globalService.setFormCodeWbsHistory(
        this.data.data!.formCode!,
        this.history
      );
    }
    this.data.onConfirm!(this.unitCascaderValue);
    // this.bankService
    //   .create(this.handleDataBeforeConfirm(this.record))
    //   .subscribe((response: any) => {
    //     if (response) {
    //       this.data.onConfirm!();
    //     }
    //   });
  }
}

export class WbsChooseConfig {
  projectItemType?: ProjectItemType;
  formCode?: string;
}
