import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'copyReportFilter',
})
export class CopyReportFilterPipe implements PipeTransform {
  transform(
    report: { value: number; label: string; date: number }[],
    filterStr: string
  ): { value: number; label: string; date: number }[] {
    return report.filter((item) =>
      item.label.toLowerCase().includes(filterStr.toLowerCase())
    );
  }
}
