<div class="dialog">
	<div class="dialog-header">【{{record.keywords}}】 - 规定值或允许偏差</div>
	<div class="dialog-body">
		<div class="grid">
			<!-- 共计660px -->
			<div class="row">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					原表描述
				</div>
				<div class="column background-module" [style.width]="700 + 'px'">
					<format-data [contentStr]="record.spadDesc ?? ''" [patternStr]="'E+380@@B'"></format-data>
				</div>
			</div>
			<div class="row">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					实测值格式
				</div>
				<div class="column" [style.width]="700 + 'px'">
					<nz-radio-group [(ngModel)]="record.measuredValueFormat">
						<label nz-radio [nzValue]="item.value" *ngFor="let item of FimpMeasuredValueFormatOptions">
							{{item.label}}
						</label>
					</nz-radio-group>
				</div>
			</div>
			<div class="row">
				<div class="column background-page text-right" [style.width]="80 + 'px'">
					实测值填写
				</div>
				<div class="column" [style.width]="400 + 'px'">
					<nz-radio-group [(ngModel)]="record.measuredValueFillType">
						<label nz-radio [nzValue]="item.value" *ngFor="let item of FimpMeasuredValueFillTypeOptions">
							{{item.label}}
						</label>
					</nz-radio-group>
				</div>
				<div class="column background-module" [style.width]="120 + 'px'">
					点击复制符号
				</div>
				<div class="column background-module" [style.width]="45 + 'px'" (click)="copy('±')">
					±
				</div>
				<div class="column background-module" [style.width]="45 + 'px'" (click)="copy('≥')">
					≥
				</div>
				<div class="column background-module" [style.width]="45 + 'px'" (click)="copy('≤')">
					≤
				</div>
				<div class="column background-module" [style.width]="45 + 'px'" (click)="copy('~')">
					~
				</div>
			</div>
		</div>
		<div class="grid">
			<div class="row">
				<div class="column background-page" [style.width]="240 + 'px'">
					规定值或设计值
				</div>
				<div class="column" [style.width]="100 + 'px'">
					<select class="color-primary" [(ngModel)]="record.spadType" (ngModelChange)="onSpadTypeChange()">
						<option [value]="undefined" disabled>选择类型</option>
						<option *ngFor="let item of FormItemMeasuredProjectSpadTypeOptions" [value]="item.value">
							{{item.label}}
						</option>
					</select>
				</div>
				<div class="column background-page" [style.width]="40 + 'px'">
					个数
				</div>
				<div class="column" [style.width]="100 + 'px'">
					<input type="tel" [(ngModel)]="record.count" [placeholder]="'输入个数'" (blur)="onCountChange()">
				</div>
				<div class="column background-page" [style.width]="40 + 'px'">
					默认
				</div>
				<div class="column" [style.width]="40 + 'px'">
					<select *ngIf="record.spadValues && record.spadValues.length > 1" class="color-primary"
						[(ngModel)]="record.defaultIndex" (ngModelChange)="onDefaultChange()">
						<option [value]="undefined" disabled>选择</option>
						<option *ngFor="let item of record.spadValues; let index = index" [value]="index">{{index + 1}}
						</option>
					</select>
				</div>
				<div class="column background-page" [style.width]="80 + 'px'">
					选择策略
				</div>
				<div class="column" [style.width]="140 + 'px'">
					<select *ngIf="record.spadValues && record.spadValues.length > 1" class="color-primary"
						[(ngModel)]="record.spadSearchType">
						<option [value]="undefined" disabled>选择类型</option>
						<option [value]="''">用户选择</option>
						<option [value]="'公路等级'">公路等级</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="column background-page" [style.width]="40 + 'px'">
					序号
				</div>
				<div class="column background-page" [style.width]="200 + 'px'">
					表达式
				</div>
				<div class="column background-page" [style.width]="100 + 'px'">
					类型
				</div>
				<div class="column background-page" [style.width]="40 + 'px'">
					参数
				</div>
				<div class="column background-page" [style.width]="100 + 'px'">
					参数名名称
				</div>
				<div class="column background-page" [style.width]="80 + 'px'">
					表内表外
				</div>
				<div class="column background-page" [style.width]="80 + 'px'">
					表内位置
				</div>
				<div class="column background-page" [style.width]="140 + 'px'">
					选择依据
				</div>
			</div>
			<div class="row" *ngFor="let item of record.spadValues ?? []; let index = index">
				<div class="column " [style.width]="40 + 'px'">
					{{index + 1}}
				</div>
				<div class="column background-module" [style.width]="200 + 'px'">
					<input class="{{!!item.spadError ? 'color-danger' : 'color-primary'}}" type="text"
						[(ngModel)]="item.spadValue" (blur)="onSpadChange(index)">
				</div>
				<div class="column" [style.width]="100 + 'px'">
					<select class="color-primary" [(ngModel)]="item.spadType">
						<option [value]="undefined" disabled>选择类型</option>
						<option *ngFor="let item of FormItemMeasuredProjectSpadTypeOptions" [value]="item.value">
							{{item.label}}
						</option>
					</select>
				</div>
				<div class="column background-module" [style.width]="40 + 'px'">
					{{item.refParamSymbol}}
				</div>
				<div class="column background-module" [style.width]="100 + 'px'">
					<input class="color-primary" type="text" [(ngModel)]="item.refParamName"
						*ngIf="!!item.refParamSymbol">
				</div>
				<div class="column background-module" [style.width]="80 + 'px'">
					<select class="color-primary" [(ngModel)]="item.refParamType" *ngIf="!!item.refParamSymbol">
						<option [value]="undefined" disabled>选择类型</option>
						<option *ngFor="let item of FormItemMeasuredProjectRefParamTypeOptions" [value]="item.value">
							{{item.label}}
						</option>
					</select>
				</div>
				<div class="column background-module" [style.width]="80 + 'px'">
					<input class="color-primary" type="text" [(ngModel)]="item.refParamVariable"
						*ngIf="!!item.refParamSymbol">
				</div>
				<div class="column background-module" [style.width]="140 + 'px'">
					<input class="color-primary" type="text" [(ngModel)]="item.keywords"
						*ngIf="record.spadValues && record.spadValues.length > 1">
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>