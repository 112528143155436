<div class="block {{disabled ? 'disable-cursor':''}}  {{editable && !disabled ? 'editable' : ''}}">
    <div class="block {{disabled || !editable?'disable-pointer':''}}" nz-button nz-popover
        (nzPopoverVisibleChange)="visibleChange($event)" [nzPopoverContent]="contentTemplate"
        nzPopoverPlacement="bottomRight" nzPopoverTrigger="click" nzPopoverOverlayClassName="user-popover">
        <h1 *ngIf="!userInfo.userId">
            <format-data class="hint {{!!required && !disabled && editable ? 'color-danger' : 'color-light'}}"
                [contentStr]="'请选择'" [patternStr]="'E|210'">
            </format-data>
        </h1>
        <h1 *ngIf="userInfo.userId" class="{{!flow.editAble ? 'name-container':''}} ">
            <format-data class="hint {{ disabled ? 'color-light': ''}}" [contentStr]="userInfo.nameLabel"
                [patternStr]="(!(editable && !disabled) && showTime && (!!userInfo.retention || !!userInfo.operateTime))?'E|100':'E|210'">
            </format-data>
            <span class="font-12 {{userInfo.editAble ? '' : 'color-light'}}"
                *ngIf="!(editable && !disabled) && showTime && (!!userInfo.retention || !!userInfo.operateTime)">
                {{!userInfo.operateTime ? userInfo.retention:(userInfo.operateTime | date:'yyyy-MM-dd HH:mm')}}
            </span>
        </h1>
        <h1 *ngIf="userInfo.organizationId">
            <format-data class="hint font-12 color-light" [contentStr]="userInfo.orgLabel ?? ''" [patternStr]="'E|210'">
            </format-data>
        </h1>
    </div>
</div>

<ng-template #contentTemplate>
    <nz-cascader #myCascader nzChangeOnSelect nzMenuClassName="user-menu" [nzShowArrow]="false"
        [nzOptions]="postTreeNodeFull" [nzAllowClear]="true" [nzExpandTrigger]="'click'" [(ngModel)]="cascaderValue"
        [nzShowSearch]="true" (ngModelChange)="onChanges($event)" [nzAutoFocus]="autoFocus">
    </nz-cascader>
</ng-template>