export enum FormHeaderType {
  FIXED = 'FIXED',
  FREE = 'FREE',
}

export const FormHeaderTypeMap = {
  [FormHeaderType.FIXED]: '固定',
  [FormHeaderType.FREE]: '自由',
};

export const FormHeaderTypeList = [
  {
    value: FormHeaderType.FIXED,
    label: FormHeaderTypeMap[FormHeaderType.FIXED],
  },
  {
    value: FormHeaderType.FREE,
    label: FormHeaderTypeMap[FormHeaderType.FREE],
  },
];
