import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';

@Component({
  selector: 'app-dialog-copy-report',
  templateUrl: './dialog-copy-report.component.html',
  styleUrls: ['./dialog-copy-report.component.scss'],
})
export class DialogCopyReportComponent {
  @Input()
  data!: DialogConfig<{
    workReports: { value: number; label: string; date: number }[];
    isSection: boolean;
  }>;

  workReportId: number = 0;

  filterStr: string = '';

  constructor(private message: NzMessageService) {}

  ngOnInit() {}

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  choose(workReportId: number) {
    this.workReportId = workReportId;
    this.data.onConfirm!(this.workReportId);
  }

  get canConfirm() {
    return true;
  }

  private submit() {
    this.data.onConfirm!(this.workReportId);
  }
}
