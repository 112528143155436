import { RecordBase } from '../../providers/request/record-base.model';
import { Popover } from '../../utils/component-creater.util';
import { ProjectSectionUnitFormType } from '../project/project-section-unit-form-type.enum';
import { FileCountersignStatus } from './file-countersign-status.enum';
import { FormCopiesType } from './form-copies-type.enum';
import { FormPagesType } from './form-pages-type.enum';
import { FormType } from './form-type.enum';
import { FormVersion } from './form-version.enum';
import { WorkExecuteUser } from './work-execute-user.model';
import { WorkReportStatus } from './work-report-status.enum';

export class WorkReportBase extends RecordBase {
  [key: string]: any;
  projectId?: number;
  sectionId?: number;
  sectionCode?: number;
  sectionItemId?: number;
  sectionItemName?: string;
  projectTypeCode?: string;
  parentMenuId?: number;
  menuId?: number;
  formId?: number;
  formVersionNo?: number;
  formCodeId?: number;
  formCodeVer?: string;
  formCodeSnId?: number;
  formCodeSn?: number;
  formFileId?: string;
  formType?: FormType;
  formPagesType?: FormPagesType;
  formCopiesType?: FormCopiesType;
  formCopyable?: boolean;
  formCopiesOrder?: number;
  formStartDate?: number;
  formStartDateDate?: Date;
  formFinishDate?: number;
  formFinishDateDate?: Date;
  formStarted?: boolean;
  formCompleted?: boolean;
  formSource?: ProjectSectionUnitFormType;
  materialBatchCode?: string;
  sn?: string;
  code?: string;
  fileCode?: string;
  appId?: string;
  name?: string;
  category?: string;
  constructionOrgName?: string;
  supervisionOrgName?: string;
  formVersion?: FormVersion;
  fileGroup?: string;
  workFlowGroupId?: number;
  filledDate?: number;
  filledUserId?: number;
  filledUserMobile?: string;
  filledUserName?: string;
  filledOrgName?: string;
  publishUserId?: number;
  publishUserMobile?: string;
  publishUserName?: string;
  publishOrgName?: string;
  status?: WorkReportStatus;
  replyStatus?: boolean;
  receiveId?: number;
  replyId?: number;
  fileCountersignStatus?: FileCountersignStatus;
  fileCountersignSourceId?: number;
  fileCountersignId?: number;
  fileCountersign?: boolean;
  rejectReason?: string;
  cancleReason?: string;
  nextExcuteUsers?: Array<WorkExecuteUser>;
  orginFiles?: string;
  orginFilesList?: string[] = [];
  pdfFiles?: string;
  pdfFilesList?: string[] = [];
  numbersOfPdfPages?: number;
  signHalfFiles?: string;
  signHalfFilesList?: string[] = [];
  signFiles?: string;
  signFilesList?: string[] = [];
  fileList?: string[] = [];
  evidenceFiles?: string;
  attachFiles?: string;
  extField1?: string;
  extField2?: string;
  extField3?: string;
  extField4?: string;
  extField5?: string;
  extField6?: string;
  extField7?: string;
  extField8?: string;
  extField9?: string;
  extField10?: string;
  remark?: string;
  // 以下字段用于工单列表，前端自用
  nextExecuteUserName?: string;
  nextExecuteUserId?: number;
  codeName?: string;
  fileUrls?: string[];
  log?: string;
  simplePop?: Popover;
  color?: string;
}
