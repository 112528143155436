import { Component, Input } from '@angular/core';
import { ExcelRangecellValueShow } from '../../models/form/excel-range-cell-value-show.model';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import { FormItemMeasuredProjectRefParamTypeOptions } from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from '../../models/form/form-item-measured-project-spad.model';
import { DetectionInfo } from '../spreadsheet-form/spreadsheet-form.component';
import { FimpMeasuredValueFormat } from '../../models/form/fimp-measured-value-format.enum';

@Component({
  selector: 'measured-display',
  templateUrl: './measured-display.component.html',
  styleUrls: ['./measured-display.component.scss'],
})
export class MeasuredDisplayComponent {
  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;
  isMeasured = false;
  @Input()
  set valueShow(value: ExcelRangecellValueShow | undefined) {
    if (!value) {
      return;
    }
    console.log('valueShow', value);
    this._valueShow = value;
    this.prepareData();
  }

  get valueShow() {
    return this._valueShow;
  }

  _valueShow: ExcelRangecellValueShow = new ExcelRangecellValueShow();

  @Input()
  set formItemId(value: number | undefined) {
    this._formItemId = value;
    this.prepareData();
  }

  get formItemId(): number | undefined {
    return this._formItemId;
  }

  _formItemId?: number;

  @Input()
  set measuredProjects(value: FormItemMeasuredProjectInfo[]) {
    this._measuredProjects = value;
    this.prepareData();
  }

  get measuredProjects() {
    return this._measuredProjects;
  }

  private _measuredProjects: FormItemMeasuredProjectInfo[] = [];

  @Input()
  fontSize = 10;

  @Input()
  set isZp(value: boolean) {
    this._isZp = value;
    this.prepareData();
  }

  get isZp() {
    return this._isZp;
  }

  _isZp = false;

  // showDevation = false;

  precision = 0;

  constructor() {}

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  descList: string[] = [];

  detectionInfo = new DetectionInfo();

  currentSpad = new FormItemMeasuredProjectSpad();

  // recommandRemark = '';

  realVal1List: { value: string; hasError: boolean }[][] = [];

  realVal2List: { value: string; hasError: boolean }[][] = [];

  special = false;

  h = '100';

  spMap: { [key: string]: string } = {
    '-1': 'Ⅰ类',
    '-2': 'Ⅱ类',
    '-3': 'Ⅲ类',
  };

  prepareData() {
    if (
      !this.formItemId ||
      !this.measuredProjects ||
      this.measuredProjects.length === 0
    ) {
      return;
    }

    let data = new FormItemMeasuredProjectInfo();
    for (const item of this.measuredProjects) {
      if (item.formItemIds && item.formItemIds.includes(this.formItemId)) {
        data = JSON.parse(JSON.stringify(item));
        break;
      }
    }
    if (!data.fieldFormItemIdMap) {
      return;
    }
    for (const key of Object.keys(data.fieldFormItemIdMap!)) {
      if (data.fieldFormItemIdMap[key] === `${this.formItemId}`) {
        if (data.keywords?.includes('桩身完整性') && key === 'measuredValue') {
          this.special = true;
          data.measuredValue = data.measuredValue
            ?.replaceAll('Ⅰ类', '-1')
            .replaceAll('Ⅱ类', '-2')
            .replaceAll('Ⅲ类', '-3');
        }
        if (data.keywords?.includes('桩身完整性') && key === 'averageValue') {
          data.averageValue = data.averageValue
            ?.replaceAll('Ⅰ类', '-1')
            .replaceAll('Ⅱ类', '-2')
            .replaceAll('Ⅲ类', '-3');
        }
        if (
          key === 'measuredValue' &&
          (!!this.valueShow?.splitCells || this.special)
        ) {
          this.isMeasured = true;
        }
      }
    }
    if (!this.isZp && data.keywords?.includes('桩身完整性')) {
      this.isMeasured = false;
    }
    console.log('isMeasured', this.isMeasured);
    if (!this.isMeasured) {
      return;
    }
    if (data.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      this.record = this.handleDataBeforeInit(data);
    } else {
      this.parseRealData(data.deviationValue);
    }
  }

  private handleDataBeforeInit(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    if (data.designValue) {
      try {
        const designValues = data.designValue.replace(' ', '\n').split('\n');
        data.designValue1 = !!designValues[0].trim()
          ? designValues[0].trim()
          : '';
        data.designValue2 = !!designValues[1].trim()
          ? designValues[1].trim()
          : '';
      } catch (e) {}
    }
    this.parseRealData(data.measuredValue);
    console.log('vol1', this.realVal1List);

    if (data.spadValues && data.spadValues.length > 0) {
      for (let i = 0; i < data.spadValues.length; i++) {
        if (data.spadValues[i].selected) {
          setTimeout(() => {
            // 为了解决ngModelChange的问题，延迟100ms
            this.selectBias(i);
          }, 100);
          break;
        }
      }
    }
    return data;
  }

  // 通过用户选择规定值或允许偏差值，计算实际值
  selectBias(index: number) {
    for (const item of this.record.spadValues!) {
      item.selected = false;
    }
    this.record.spadValues![index].selected = true;
    this.detectionInfo.selectIndex = index;
    this.detectionInfo.biasStr = `${this.record.spadValues![index].spadValue}${
      this.record.spadValues![index].keywords
        ? `(${this.record.spadValues![index].keywords})`
        : ''
    }`;
    this.currentSpad = JSON.parse(
      JSON.stringify(this.record.spadValues![index])
    );

    if (
      this.currentSpad.refParamType &&
      // (this.currentSpad.refParamValue === undefined ||
      //   this.currentSpad.refParamValue === '') &&
      this.record.refParamValue !== undefined &&
      this.record.refParamValue !== ''
    ) {
      this.currentSpad.refParamValue = this.record.refParamValue;
    }
    this.parseBiasStr();
    this.calcAll();
  }

  private calcAll() {
    let totalCount1 = 0;
    let totalCount2 = 0;
    let passCount = 0;
    let sumReal1 = 0;
    let sumReal2 = 0;

    let maxR = 0;

    if (this.detectionInfo.biasError) {
      for (const row of this.realVal1List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
      for (const row of this.realVal2List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
    } else {
      for (const row of this.realVal1List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount1++;
            sumReal1 += +col.value;
            if (
              +col.value >= this.detectionInfo.val1Min! &&
              +col.value <= this.detectionInfo.val1Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
      for (const row of this.realVal2List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount2++;
            sumReal2 += +col.value;
            if (
              +col.value >= this.detectionInfo.val2Min! &&
              +col.value <= this.detectionInfo.val2Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
    }
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(measuredValue?: string) {
    if (!!measuredValue) {
      console.log('measuredValue', measuredValue);
      const measuredValues = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split('\n');
      const m1 = measuredValues[0] ?? '';
      const m2 = measuredValues[1] ?? '';
      let m1List: string[] = [];
      let m2List: string[] = [];
      m1List = m1.split(' ');
      let rowCount = Math.ceil(m1List.length / 10);
      // if (rowCount < 1) {
      //   rowCount = 1;
      // }
      if (!m1) {
        rowCount = 0;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal1List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal1List[row][col] = {
            value: m1List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
      m2List = m2.split(' ');
      rowCount = Math.ceil(m2List.length / 10);
      // if (rowCount < 1) {
      //   rowCount = 1;
      // }
      if (!m2) {
        rowCount = 0;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal2List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal2List[row][col] = {
            value: m2List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      this.realVal1List = [[]];
      this.realVal2List = [[]];
      // for (let col = 0; col < 10; col++) {
      //   this.realVal1List[0][col] = {
      //     value: '',
      //     hasError: false,
      //   };
      //   this.realVal2List[0][col] = {
      //     value: '',
      //     hasError: false,
      //   };
      // }
    }
    this.h =
      this.realVal1List.length + this.realVal2List.length > 0
        ? (100 / (this.realVal1List.length + this.realVal2List.length)).toFixed(
            2
          )
        : '100';
  }

  private parseBiasStr() {
    this.detectionInfo.biasError = true;
    // this.recommandRemark = `${this.record.designValue1 ?? ''}${
    //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
    // }`;
    // this.record.remarkValue = this.recommandRemark;
    if (!this.detectionInfo.biasStr) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      return;
    }
    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.USER_FILLIN ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
    ) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      // 用户自填项，或者无需填写。我们认为这是无法识别的信息。因此无法通过这个信息进行合格率的计算。对于这种情况，我们认为解析信息有误的。
      return;
    }
    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
    ) {
      // 允许偏差值。我们认为这是可以识别的信息。因此可以通过这个信息进行合格率的计算。
      // 允许偏差：±n 或『+n, -m』
      // this.recommandRemark = `${this.record.designValue1 ?? ''}${
      //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
      // }`;
      // this.record.remarkValue = this.recommandRemark;
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      try {
        // 允许偏差有两种情况，一种是±，一种是最大值和最小值
        let biasMin = 0;
        let biasMax = 0;
        if (this.currentSpad.spadValue!.includes('±')) {
          const bias = this.currentSpad.spadValue!.replace('±', '');
          this.precision = bias.includes('.') ? bias.split('.')[1].length : 0;
          if (this.currentSpad.refParamSymbol) {
            console.log(this.currentSpad.refParamSymbol);
            const abs = +eval(
              bias.replace(
                this.currentSpad.refParamSymbol,
                this.currentSpad.refParamValue!
              )
            );
            // 纯展示。没用
            this.currentSpad.calcDisplay = '±' + abs;
            biasMin = -abs;
            biasMax = abs;
          } else {
            biasMin = -+bias;
            biasMax = +bias;
          }
        }

        if (!this.record.designValue1) {
          // 如果没有设计值，那么无法计算最大值和最小值
          return;
        }
        if (this.currentSpad.spadValue!.includes(',')) {
          const bias = this.currentSpad.spadValue!.split(',');
          biasMin = Math.min(+bias[0], +bias[1]);
          biasMax = Math.max(+bias[0], +bias[1]);
          this.precision = biasMax.toString().includes('.')
            ? biasMax.toString().split('.')[1].length
            : 0;
        }
        this.detectionInfo.val1Min = +this.record.designValue1! + biasMin;
        this.detectionInfo.val1Max = +this.record.designValue1! + biasMax;
        if (
          this.record.designValue2 !== undefined &&
          this.record.designValue2 !== '' &&
          !isNaN(+this.record.designValue2)
        ) {
          this.detectionInfo.val2Min = +this.record.designValue2! + biasMin;
          this.detectionInfo.val2Max = +this.record.designValue2! + biasMax;
        }
        // 到这里确定没有错误
        this.detectionInfo.biasError = false;
      } catch (e) {
        return;
      }
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≥', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      try {
        if (!isNaN(+bias) && !this.record.designValue1) {
          this.record.designValue1 = bias;
        }
      } catch (e) {}

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≥' + abs;
        this.detectionInfo.val1Min = +abs;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +abs;
        this.detectionInfo.val2Max = Infinity;
      } else {
        this.detectionInfo.val1Min = +bias;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +bias;
        this.detectionInfo.val2Max = Infinity;
      }

      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
    ) {
      // ≤ 规定值：≤ n

      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≤', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      try {
        if (!isNaN(+bias) && !this.record.designValue1) {
          this.record.designValue1 = bias;
        }
      } catch (e) {}

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≤' + abs;
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +abs;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +abs;
      } else {
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +bias;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +bias;
      }

      this.detectionInfo.biasError = false;
      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      // ~ 规定值：~ n
      const bias = this.currentSpad
        .spadValue!.replace('~', ' ')
        .replace('～', ' ')
        .split(' ');
      const min = Math.min(+bias[0], +bias[1]);
      const max = Math.max(+bias[0], +bias[1]);
      this.detectionInfo.val1Min = min;
      this.detectionInfo.val1Max = max;
      this.detectionInfo.val2Min = min;
      this.detectionInfo.val2Max = max;
      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≥' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≥' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≥ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = +this.record.designValue1!;
      this.detectionInfo.val1Max = Infinity;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = +this.record.designValue2!;
        this.detectionInfo.val2Max = Infinity;
      }
      this.detectionInfo.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≤' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≤' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≤ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = -Infinity;
      this.detectionInfo.val1Max = +this.record.designValue1!;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +this.record.designValue2!;
      }
      this.detectionInfo.biasError = false;
    }
  }
}
