export enum FimpMeasuredValueFormat {
  NUMERIC = 'NUMERIC',
  SEE_JS101 = 'SEE_JS101',
  SEE_JS103 = 'SEE_JS103',
  SEE_REPORT = 'SEE_REPORT',
  SEE_TXT = 'SEE_TXT',
}

export const FimpMeasuredValueFormatMap = {
  [FimpMeasuredValueFormat.NUMERIC]: '填写数值',
  [FimpMeasuredValueFormat.SEE_JS101]: '来自JS101',
  [FimpMeasuredValueFormat.SEE_JS103]: '来自JS103',
  [FimpMeasuredValueFormat.SEE_REPORT]: '见报告',
  [FimpMeasuredValueFormat.SEE_TXT]: '其他文本',
};

export const FimpMeasuredValueFormatOptions = [
  {
    label: FimpMeasuredValueFormatMap[FimpMeasuredValueFormat.NUMERIC],
    value: FimpMeasuredValueFormat.NUMERIC,
  },
  {
    label: FimpMeasuredValueFormatMap[FimpMeasuredValueFormat.SEE_JS101],
    value: FimpMeasuredValueFormat.SEE_JS101,
  },
  {
    label: FimpMeasuredValueFormatMap[FimpMeasuredValueFormat.SEE_JS103],
    value: FimpMeasuredValueFormat.SEE_JS103,
  },
  {
    label: FimpMeasuredValueFormatMap[FimpMeasuredValueFormat.SEE_REPORT],
    value: FimpMeasuredValueFormat.SEE_REPORT,
  },
  {
    label: FimpMeasuredValueFormatMap[FimpMeasuredValueFormat.SEE_TXT],
    value: FimpMeasuredValueFormat.SEE_TXT,
  },
];
