export enum FormItemWidgetType {
  EMPTY = 'EMPTY',
  NORMAL = 'NORMAL',
  NUMERIC = 'NUMERIC',
  PERCENT = 'PERCENT',
  DATE = 'DATE',
  TIME = 'TIME',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  SIGN = 'SIGN',
  SEAL = 'SEAL',
  IMAGE = 'IMAGE',
  // LINE_CHART = 'LINE_CHART',
  // SKETCH_CHART = 'SKETCH_CHART',
  LOCATION = 'LOCATION',
  // POSITION = 'POSITION',
  PROJECT = 'PROJECT',
  WBS = 'WBS',
  PERSON = 'PERSON',
  CODE = 'CODE',
  // FILE = 'FILE',
  SIGN_SEAL = 'SIGN_SEAL',
  WEATHER = 'WEATHER',
  DETECTION = 'DETECTION',
  DETECTION2 = 'DETECTION2',
  DETECTION3 = 'DETECTION3',
  WORK_PROCEDURE = 'WORK_PROCEDURE',

  FLOW_CC = 'FLOW_CC',
  FLOW_CC_ONE = 'FLOW_CC_ONE',
  FLOW_PRESENT = 'FLOW_PRESENT',
  FLOW_PRESENT_ONE = 'FLOW_PRESENT_ONE',
  FILLED_USER = 'FILLED_USER',
  MATERIAL = 'MATERIAL',

  MEASURED_PROJECT = 'MEASURED_PROJECT',
}
export const FormItemWidgetTypeMap = {
  [FormItemWidgetType.EMPTY]: '空值',
  [FormItemWidgetType.NORMAL]: '常规',
  [FormItemWidgetType.NUMERIC]: '数值',
  [FormItemWidgetType.PERCENT]: '百分比',
  [FormItemWidgetType.DATE]: '日期',
  [FormItemWidgetType.TIME]: '时间',
  [FormItemWidgetType.RADIO]: '单选',
  [FormItemWidgetType.CHECKBOX]: '复选',
  [FormItemWidgetType.SELECT]: '选择',
  [FormItemWidgetType.SIGN]: '签字',
  [FormItemWidgetType.SEAL]: '盖章',
  [FormItemWidgetType.IMAGE]: '图片',
  // [FormItemWidgetType.LINE_CHART]: '折线图',
  // [FormItemWidgetType.SKETCH_CHART]: '手绘图',
  [FormItemWidgetType.LOCATION]: '地区',
  // [FormItemWidgetType.POSITION]: '定位',
  [FormItemWidgetType.PROJECT]: '工程信息',
  [FormItemWidgetType.WBS]: 'WBS',
  [FormItemWidgetType.PERSON]: '人员信息',
  [FormItemWidgetType.CODE]: '编码',
  // [FormItemWidgetType.FILE]: '文件添加',
  [FormItemWidgetType.SIGN_SEAL]: '签字盖章',
  [FormItemWidgetType.WEATHER]: '天气',
  [FormItemWidgetType.DETECTION]: '实测项目(JS)',
  [FormItemWidgetType.DETECTION2]: '实测项目(JS101)',
  [FormItemWidgetType.DETECTION3]: '实测项目(JS103)',
  [FormItemWidgetType.WORK_PROCEDURE]: '工序',
  [FormItemWidgetType.FLOW_CC]: '抄送',
  [FormItemWidgetType.FLOW_CC_ONE]: '抄送(单人)',
  [FormItemWidgetType.FLOW_PRESENT]: '主送',
  [FormItemWidgetType.FLOW_PRESENT_ONE]: '主送(单人)',
  [FormItemWidgetType.FILLED_USER]: '填写人',
  [FormItemWidgetType.MATERIAL]: '材料',
  [FormItemWidgetType.MEASURED_PROJECT]: '实测项目(ZJ/ZP)',
};

export const FormItemWidgetTypeList = [
  {
    value: FormItemWidgetType.EMPTY,
    label: FormItemWidgetTypeMap[FormItemWidgetType.EMPTY],
  },
  {
    value: FormItemWidgetType.NORMAL,
    label: FormItemWidgetTypeMap[FormItemWidgetType.NORMAL],
  },
  {
    value: FormItemWidgetType.NUMERIC,
    label: FormItemWidgetTypeMap[FormItemWidgetType.NUMERIC],
  },
  {
    value: FormItemWidgetType.PERCENT,
    label: FormItemWidgetTypeMap[FormItemWidgetType.PERCENT],
  },
  {
    value: FormItemWidgetType.DATE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.DATE],
  },
  {
    value: FormItemWidgetType.TIME,
    label: FormItemWidgetTypeMap[FormItemWidgetType.TIME],
  },
  {
    value: FormItemWidgetType.RADIO,
    label: FormItemWidgetTypeMap[FormItemWidgetType.RADIO],
  },
  {
    value: FormItemWidgetType.CHECKBOX,
    label: FormItemWidgetTypeMap[FormItemWidgetType.CHECKBOX],
  },
  {
    value: FormItemWidgetType.SELECT,
    label: FormItemWidgetTypeMap[FormItemWidgetType.SELECT],
  },
  {
    value: FormItemWidgetType.SIGN,
    label: FormItemWidgetTypeMap[FormItemWidgetType.SIGN],
  },
  {
    value: FormItemWidgetType.SEAL,
    label: FormItemWidgetTypeMap[FormItemWidgetType.SEAL],
  },
  {
    value: FormItemWidgetType.SIGN_SEAL,
    label: FormItemWidgetTypeMap[FormItemWidgetType.SIGN_SEAL],
  },
  {
    value: FormItemWidgetType.IMAGE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.IMAGE],
  },
  // {
  //   value: FormItemWidgetType.LINE_CHART,
  //   label: FormItemWidgetTypeMap[FormItemWidgetType.LINE_CHART],
  // },
  // {
  //   value: FormItemWidgetType.SKETCH_CHART,
  //   label: FormItemWidgetTypeMap[FormItemWidgetType.SKETCH_CHART],
  // },
  {
    value: FormItemWidgetType.LOCATION,
    label: FormItemWidgetTypeMap[FormItemWidgetType.LOCATION],
  },
  // {
  //   value: FormItemWidgetType.POSITION,
  //   label: FormItemWidgetTypeMap[FormItemWidgetType.POSITION],
  // },
  {
    value: FormItemWidgetType.PROJECT,
    label: FormItemWidgetTypeMap[FormItemWidgetType.PROJECT],
  },
  {
    value: FormItemWidgetType.WBS,
    label: FormItemWidgetTypeMap[FormItemWidgetType.WBS],
  },
  {
    value: FormItemWidgetType.PERSON,
    label: FormItemWidgetTypeMap[FormItemWidgetType.PERSON],
  },
  {
    value: FormItemWidgetType.CODE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.CODE],
  },
  // {
  //   value: FormItemWidgetType.FILE,
  //   label: FormItemWidgetTypeMap[FormItemWidgetType.FILE],
  // },
  {
    value: FormItemWidgetType.WEATHER,
    label: FormItemWidgetTypeMap[FormItemWidgetType.WEATHER],
  },
  {
    value: FormItemWidgetType.DETECTION,
    label: FormItemWidgetTypeMap[FormItemWidgetType.DETECTION],
  },
  {
    value: FormItemWidgetType.DETECTION2,
    label: FormItemWidgetTypeMap[FormItemWidgetType.DETECTION2],
  },
  {
    value: FormItemWidgetType.DETECTION3,
    label: FormItemWidgetTypeMap[FormItemWidgetType.DETECTION3],
  },
  {
    value: FormItemWidgetType.WORK_PROCEDURE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.WORK_PROCEDURE],
  },
  {
    value: FormItemWidgetType.FLOW_CC,
    label: FormItemWidgetTypeMap[FormItemWidgetType.FLOW_CC],
  },
  {
    value: FormItemWidgetType.FLOW_CC_ONE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.FLOW_CC_ONE],
  },
  {
    value: FormItemWidgetType.FLOW_PRESENT,
    label: FormItemWidgetTypeMap[FormItemWidgetType.FLOW_PRESENT],
  },
  {
    value: FormItemWidgetType.FLOW_PRESENT_ONE,
    label: FormItemWidgetTypeMap[FormItemWidgetType.FLOW_PRESENT_ONE],
  },
  {
    value: FormItemWidgetType.FILLED_USER,
    label: FormItemWidgetTypeMap[FormItemWidgetType.FILLED_USER],
  },
  {
    value: FormItemWidgetType.MATERIAL,
    label: FormItemWidgetTypeMap[FormItemWidgetType.MATERIAL],
  },
  {
    value: FormItemWidgetType.MEASURED_PROJECT,
    label: FormItemWidgetTypeMap[FormItemWidgetType.MEASURED_PROJECT],
  },
];
