<div class="dialog">
	<div class="dialog-header">
		选择要复制的表单
	</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row">
				<div class="column background-page" [style.width]="(!!data.data?.isSection ? 920 : 420) + 'px'">
					<input type="text" [(ngModel)]="filterStr" placeholder="输入关键字筛选">
				</div>
			</div>
			<div class="row">
				<div class="column background-page" [style.width]="800 + 'px'" *ngIf="!!data.data?.isSection">
					工程部位（工程类型相同）
				</div>
				<div class="column background-page" [style.width]="120 + 'px'">
					填写日期
				</div>
				<div class="column background-page" [style.width]="300 + 'px'" *ngIf="!data.data?.isSection">
					关键字
				</div>
			</div>
			<div class="scroll">
				<div class="row" *ngFor="let item of data.data?.workReports ?? [] | copyReportFilter:filterStr"
					(click)="choose(item.value!)">
					<div class="column {{workReportId === item.value ? 'color-primary' : '' }}"
						[style.width]="800 + 'px'" *ngIf="!!data.data?.isSection">
						<format-data [contentStr]="item.label" [patternStr]="'E|800'"></format-data>
					</div>
					<div class="column {{workReportId === item.value ? 'color-primary' : '' }}"
						[style.width]="120 + 'px'">
						{{item.date | date: 'yyyy-MM-dd'}}
					</div>
					<div class="column {{workReportId === item.value ? 'color-primary' : '' }}"
						[style.width]="300 + 'px'" *ngIf="!data.data?.isSection">
						<format-data [contentStr]="item.label" [patternStr]="'E+300'"></format-data>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<!-- <button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button> -->
	</div>
</div>