import { EventType } from './../../providers/event.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EventService } from '../../providers/event.service';
import { CharWidthCalcUtil } from '../../utils/char-width-calc.util';
import { SpreadsheetFormComponent } from '../spreadsheet-form/spreadsheet-form.component';

@Component({
  selector: 'detection-real',
  templateUrl: './detection-real.component.html',
  styleUrls: ['./detection-real.component.scss'],
})
export class DetectionRealComponent implements AfterViewInit {
  @ViewChild('container') container?: ElementRef<any>;
  @ViewChild('text') textarea?: ElementRef<any>;

  @Input()
  disable: boolean = false;

  val1: string = '';

  val2: string = '';

  oldVal1 = '';

  oldVal2 = '';

  realVal1: number[] = [];

  subRealVal1: number[] = [];

  realVal2: number[] = [];

  subRealVal2: number[] = [];

  valInputRow = 1;

  _val1Min = -Infinity;

  textAreaMinHeight = 0;

  textAreaHeight = 0;

  textAreaWidth = 0;

  textAreaTop = 0;

  textAreaLeft = 0;

  textAreaDisplay = false;

  textAreaEditingIndex = -1;

  currentVal = '';

  inited = false;

  @Input()
  set initValue(val: string | undefined) {
    if (!this.inited) {
      this.inited = true;
      setTimeout(() => {
        if (val) {
          const vals = val.replaceAll('&ensp;', ' ').split('<br/>');
          if (vals.length > 0) {
            this.val1 = vals[0];
            console.log('initValue val1: ' + this.val1);
            this.onVal1Change();
          }
          if (vals.length > 1) {
            this.val2 = vals[1];
            this.onVal2Change();
          }
        }
      }, 300);
    }
  }

  @Input()
  set val1Min(val: number) {
    this._val1Min = val;
    this.calcResult();
  }

  get val1Min() {
    return this._val1Min;
  }

  _val1Max = Infinity;

  @Input()
  set val1Max(val: number) {
    this._val1Max = val;
    this.calcResult();
  }

  get val1Max() {
    return this._val1Max;
  }

  _val2Min = -Infinity;

  @Input()
  set val2Min(val: number) {
    this._val2Min = val;
    this.calcResult();
  }

  get val2Min() {
    return this._val2Min;
  }

  _val2Max = Infinity;

  @Input()
  set val2Max(val: number) {
    this._val2Max = val;
    this.calcResult();
  }

  get val2Max() {
    return this._val2Max;
  }

  editing = false;

  itemWidth = 0;

  hasTwoLines = false;

  _needCalc = false;

  @Input()
  set twoLines(val: boolean) {
    this.hasTwoLines = val;
    if (!this.hasTwoLines) {
      this.val2 = '';
      this.realVal2 = [];
    }
    this.textAreaMinHeight = this.container?.nativeElement.clientHeight ?? 0;
    if (this.hasTwoLines) {
      this.textAreaMinHeight =
        (this.container?.nativeElement.clientHeight ?? 0) / 2;
    }
    this.calcResult();
  }

  @Input()
  set needCalc(val: string) {
    console.log('needCalc: ' + val);
    this._needCalc = val === 'true';
    this.calcResult();
  }

  @Output()
  realValChange: EventEmitter<{
    realVals: [number[], number[]];
    passVal?: number;
  }> = new EventEmitter<{
    realVals: [number[], number[]];
    passVal?: number;
  }>();

  calcResult() {
    if (!this.inited) {
      return;
    }
    this.itemWidth = 0;
    for (const item of this.realVal1) {
      const w = CharWidthCalcUtil.calcCharWidth(item.toString(), 12);
      if (w > this.itemWidth) {
        this.itemWidth = w;
      }
    }
    for (const item of this.realVal2) {
      const w = CharWidthCalcUtil.calcCharWidth(item.toString(), 12);
      if (w > this.itemWidth) {
        this.itemWidth = w;
      }
    }
    this.itemWidth += 4;
    const total = this.realVal1.length + this.realVal2.length;
    let pass = 0;
    for (const item of this.realVal1) {
      if (item >= this.val1Min && item <= this.val1Max) {
        pass++;
      }
    }
    for (const item of this.realVal2) {
      if (item >= this.val2Min && item <= this.val2Max) {
        pass++;
      }
    }
    const passVal = +((pass / total) * 100).toFixed(2);
    const displayCount = Math.floor(this.textAreaWidth / this.itemWidth);
    if (this.hasTwoLines) {
      if (this.realVal1.length > displayCount) {
        this.subRealVal1 = this.realVal1.slice(0, displayCount - 1);
      } else {
        this.subRealVal1 = this.realVal1;
      }
      if (this.realVal2.length > displayCount) {
        this.subRealVal2 = this.realVal2.slice(0, displayCount - 1);
      } else {
        this.subRealVal2 = this.realVal2;
      }
    } else {
      if (this.realVal1.length > 2 * displayCount) {
        this.subRealVal1 = this.realVal1.slice(0, 2 * displayCount - 1);
      } else {
        this.subRealVal1 = this.realVal1;
      }
    }

    this.realValChange.emit({
      realVals: [this.realVal1, this.realVal2],
      passVal,
    });
  }

  prepareVal1Input($event: MouseEvent) {
    if (this.disable) {
      return;
    }
    this.currentVal = this.val1;
    this.textAreaEditingIndex = 1;
    this.textAreaDisplay = true;
    this.currentVal = this.val1;
    setTimeout(() => {
      this.textarea?.nativeElement.focus();
    }, 100);
    this.onValInput();
  }

  prepareVal2Input($event: MouseEvent) {
    if (this.disable) {
      return;
    }
    this.currentVal = this.val2;
    this.textAreaEditingIndex = 2;
    this.textAreaDisplay = true;
    this.currentVal = this.val2;
    setTimeout(() => {
      this.textarea?.nativeElement.focus();
    }, 100);
    this.onValInput();
  }

  onValInput() {
    const width = CharWidthCalcUtil.calcCharWidth(
      this.currentVal.toString(),
      13
    );
    const row = Math.ceil(width / this.container?.nativeElement.clientWidth);
    if (row * 18 > this.textAreaMinHeight) {
      this.textAreaHeight = row * 16 + 8;
    } else {
      this.textAreaHeight = this.textAreaMinHeight;
    }
    const rect = this.container?.nativeElement.getBoundingClientRect();
    if (this.hasTwoLines) {
      if (this.textAreaEditingIndex === 2) {
        this.textAreaTop = rect.top + rect.height / 2;
      }
      if (this.textAreaEditingIndex === 1) {
        const bottom = rect.top + rect.height / 2;
        this.textAreaTop = bottom - this.textAreaHeight;
      }
    } else {
      this.textAreaTop = rect.top;
    }
    this.textAreaLeft = rect.left;
  }

  onValInputFinish() {
    if (this.textAreaEditingIndex === 1) {
      this.val1 = this.currentVal;
      console.log('onValInputFinish val1: ' + this.val1);
      this.onVal1Change();
    }
    if (this.textAreaEditingIndex === 2) {
      this.val2 = this.currentVal;
      this.onVal2Change();
    }
    this.currentVal = '';
    this.textAreaDisplay = false;
    this.textAreaEditingIndex = -1;
  }

  onVal1Change() {
    // this.editing = false;
    if (this.val1 === this.oldVal1) {
      return;
    }
    this.realVal1 = [];
    const result = this.val1.split(' ').map((item) => (!!item ? +item : NaN));
    for (const item of result) {
      if (!isNaN(item)) {
        this.realVal1.push(item);
      }
    }
    this.val1 = this.realVal1.join(' ');
    this.oldVal1 = this.val1;
    console.log('val1: ' + this.val1);
    this.calcResult();
  }

  onVal2Change() {
    // this.editing = false;
    if (this.val2 === this.oldVal2) {
      return;
    }
    this.realVal2 = [];
    const result = this.val2.split(' ').map((item) => (!!item ? +item : NaN));
    for (const item of result) {
      if (!isNaN(item)) {
        this.realVal2.push(item);
      }
    }
    this.val2 = this.realVal2.join(' ');
    this.oldVal2 = this.val2;
    this.calcResult();
  }

  constructor(private events: EventService) {}

  subsciption = () => this.onValInputFinish();
  redraw = () => this.onDraw();

  ngOnInit(): void {
    this.events.on(SpreadsheetFormComponent.ON_SCROLL, this.subsciption);
    this.events.on(EventType.REDRAW_TABLE, this.redraw);
  }

  ngOnDestroy(): void {
    this.events.destroyListener(
      SpreadsheetFormComponent.ON_SCROLL,
      this.subsciption
    );
    this.events.destroyListener(EventType.REDRAW_TABLE, this.redraw);
  }

  ngAfterViewInit(): void {
    this.onDraw();
  }

  private onDraw() {
    setTimeout(() => {
      this.textAreaWidth = this.container?.nativeElement.clientWidth;
      this.textAreaMinHeight = this.container?.nativeElement.clientHeight ?? 0;
      if (this.hasTwoLines) {
        this.textAreaMinHeight =
          (this.container?.nativeElement.clientHeight ?? 0) / 2;
      }
      this.textAreaHeight = this.textAreaMinHeight;
      if (this.inited) {
        this.calcResult();
      }
    }, 300);
  }
}
