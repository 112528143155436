<div class="block {{disable ? 'disable' : ''}}" #container>
	<div class="cell {{hasTwoLines ? 'hasTwoLines' : ''}}" (click)="prepareVal1Input($event)">
		<span class="val {{+item > _val1Max || +item < _val1Min ? 'color-danger' : ''}}"
			*ngFor="let item of subRealVal1" [style.width]="itemWidth + 'px'">
			{{item}}
		</span>

		<span class="val" *ngIf="realVal1.length !== subRealVal1.length">
			...
		</span>
	</div>
	<div class="cell {{hasTwoLines ? 'hasTwoLines' : ''}}" (click)="prepareVal2Input($event)" *ngIf="hasTwoLines">
		<span class="val {{+item > _val2Max || +item < _val2Min ? 'color-danger' : ''}}"
			*ngFor="let item of subRealVal2" [style.width]="itemWidth + 'px'">
			{{item}}
		</span>
		<span class="val" *ngIf="realVal2.length !== subRealVal2.length">
			...
		</span>
	</div>
</div>

<textarea #text *ngIf="textAreaDisplay" [(ngModel)]="currentVal" (ngModelChange)="onValInput()"
	(blur)="onValInputFinish()" detectionRealVal [style.width]="textAreaWidth + 'px'"
	[style.minHeight]="textAreaMinHeight + 'px'" [style.height]="textAreaHeight + 'px'" [style.top]="textAreaTop + 'px'"
	[style.left]="textAreaLeft + 'px'">
</textarea>