import { Injectable } from '@angular/core';
import { from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private listeners: any;
  private eventsSubject: Subject<any>;
  private events: any;

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = from(this.eventsSubject);

    this.events.subscribe(({ name, args }: any) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  on(name: string, listener: any): void {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  broadcast(name: string, ...args: Array<any>): void {
    this.eventsSubject.next({
      name,
      args,
    });
  }

  destroyListener(name: string, listener: any): void {
    if (this.listeners[name] && this.listeners[name].indexOf(listener) > -1) {
      this.listeners[name].splice(this.listeners[name].indexOf(listener), 1);
    }
  }
}

export enum EventType {
  SummaryChange = 'SummaryChange',
  AuditChange = 'AuditChange',
  BreadcrumbEvent = 'BreadcrumbEvent',
  WbsChangeEvent = 'WbsChangeEvent',
  WbsTitleChangeEvent = 'WbsTitleChangeEvent',
  OrgChangeEvent = 'OrgChangeEvent',
  OrgChangeByAccountingEvent = 'OrgChangeByAccountingEvent',
  AssistTableChange = 'AssistTableChange',
  SalaryUpdateEvent = 'SalaryUpdateEvent',
  SocketEvent = 'SocketEvent',
  SubjectSystemChangeEvent = 'SubjectSystemChangeEvent',
  SubjectItemUpdateEvent = 'SubjectItemUpdateEvent',
  FilePreviewVerticalEvent = 'FilePreviewVerticalEvent',
  AccountingMonthChangeEvent = 'AccountingMonthChangeEvent',
  SignUserSelected = 'SignUserSelected',
  CcChange = 'CcChange',
  PresentChange = 'PresentChange',
  PageCacheSearch = 'PageCacheSearch',
  // STOP_AUTO_SAVE = 'STOP_AUTO_SAVE',
  // START_AUTO_SAVE = 'START_AUTO_SAVE',
  SAVE_MEASURE = 'SAVE_MEASURE',
  SAVE_RECORD = 'SAVE_RECORD',
  BREAK_SHIELD = 'BREAK_SHIELD',
  REDRAW_TABLE = 'REDRAW_TABLE',
}
