export enum FimpAverageTypeType {
  AVERAGE_VALUE = 'AVERAGE_VALUE',
  TYPICAL_VALUE = 'TYPICAL_VALUE',
}

export const FimpAverageTypeTypeMap = {
  [FimpAverageTypeType.AVERAGE_VALUE]: '平均值',
  [FimpAverageTypeType.TYPICAL_VALUE]: '代表值',
};

export const FimpAverageTypeTypeOptions = [
  {
    label: FimpAverageTypeTypeMap[FimpAverageTypeType.AVERAGE_VALUE],
    value: FimpAverageTypeType.AVERAGE_VALUE,
  },
  {
    label: FimpAverageTypeTypeMap[FimpAverageTypeType.TYPICAL_VALUE],
    value: FimpAverageTypeType.TYPICAL_VALUE,
  },
];
