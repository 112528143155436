export const s12 = {
  '0': 8,
  '1': 8,
  '2': 8,
  '3': 8,
  '4': 8,
  '5': 8,
  '6': 8,
  '7': 8,
  '8': 8,
  '9': 8,
  '!': 4,
  ' ': 2,
  '"': 6,
  '#': 8,
  $: 8,
  '%': 11,
  '&': 9,
  "'": 4,
  '(': 5,
  ')': 5,
  '*': 6,
  '+': 8,
  ',': 4,
  '-': 6,
  '.': 4,
  '/': 4,
  ':': 4,
  ';': 4,
  '<': 8,
  '=': 8,
  '>': 8,
  '?': 6,
  '@': 11,
  A: 8,
  B: 8,
  C: 9,
  D: 9,
  E: 7,
  F: 7,
  G: 9,
  H: 9,
  I: 3,
  J: 6,
  K: 8,
  L: 7,
  M: 10,
  N: 9,
  O: 9,
  P: 8,
  Q: 9,
  R: 8,
  S: 8,
  T: 8,
  U: 9,
  V: 8,
  W: 12,
  X: 8,
  Y: 8,
  Z: 8,
  '[': 5,
  '\\': 4,
  ']': 5,
  '^': 8,
  _: 7,
  '`': 6,
  a: 7,
  b: 7,
  c: 7,
  d: 7,
  e: 7,
  f: 4,
  g: 7,
  h: 7,
  i: 3,
  j: 3,
  k: 7,
  l: 3,
  m: 10,
  n: 7,
  o: 7,
  p: 7,
  q: 7,
  r: 5,
  s: 6,
  t: 4,
  u: 7,
  v: 7,
  w: 9,
  x: 6,
  y: 7,
  z: 6,
  '{': 5,
  '|': 3,
  '}': 5,
  '~': 8,
};
export const s13 = {
  '0': 8,
  '1': 8,
  '2': 8,
  '3': 8,
  '4': 8,
  '5': 8,
  '6': 8,
  '7': 8,
  '8': 8,
  '9': 8,
  '!': 4,
  ' ': 4,
  '"': 6,
  '#': 8,
  $: 8,
  '%': 12,
  '&': 9,
  "'": 4,
  '(': 5,
  ')': 5,
  '*': 6,
  '+': 8,
  ',': 4,
  '-': 6,
  '.': 4,
  '/': 4,
  ':': 4,
  ';': 4,
  '<': 8,
  '=': 8,
  '>': 8,
  '?': 7,
  '@': 12,
  A: 9,
  B: 8,
  C: 9,
  D: 9,
  E: 8,
  F: 7,
  G: 10,
  H: 10,
  I: 3,
  J: 7,
  K: 8,
  L: 7,
  M: 11,
  N: 10,
  O: 10,
  P: 8,
  Q: 10,
  R: 8,
  S: 8,
  T: 8,
  U: 10,
  V: 9,
  W: 13,
  X: 9,
  Y: 8,
  Z: 9,
  '[': 5,
  '\\': 4,
  ']': 5,
  '^': 8,
  _: 8,
  '`': 6,
  a: 7,
  b: 8,
  c: 7,
  d: 8,
  e: 7,
  f: 5,
  g: 8,
  h: 8,
  i: 3,
  j: 3,
  k: 7,
  l: 3,
  m: 11,
  n: 8,
  o: 8,
  p: 8,
  q: 8,
  r: 5,
  s: 7,
  t: 5,
  u: 8,
  v: 7,
  w: 10,
  x: 7,
  y: 7,
  z: 7,
  '{': 5,
  '|': 3,
  '}': 5,
  '~': 8,
};
export const s14 = {
  '0': 9,
  '1': 9,
  '2': 9,
  '3': 9,
  '4': 9,
  '5': 9,
  '6': 9,
  '7': 9,
  '8': 9,
  '9': 9,
  '!': 4,
  ' ': 3,
  '"': 7,
  '#': 9,
  $: 9,
  '%': 13,
  '&': 10,
  "'": 4,
  '(': 5,
  ')': 5,
  '*': 6,
  '+': 9,
  ',': 4,
  '-': 6,
  '.': 4,
  '/': 4,
  ':': 4,
  ';': 4,
  '<': 9,
  '=': 9,
  '>': 9,
  '?': 7,
  '@': 13,
  A: 9,
  B: 9,
  C: 10,
  D: 10,
  E: 8,
  F: 8,
  G: 10,
  H: 10,
  I: 4,
  J: 7,
  K: 9,
  L: 8,
  M: 12,
  N: 10,
  O: 11,
  P: 9,
  Q: 11,
  R: 9,
  S: 9,
  T: 9,
  U: 10,
  V: 9,
  W: 13,
  X: 9,
  Y: 9,
  Z: 9,
  '[': 5,
  '\\': 4,
  ']': 5,
  '^': 9,
  _: 8,
  '`': 7,
  a: 8,
  b: 8,
  c: 8,
  d: 8,
  e: 8,
  f: 5,
  g: 8,
  h: 8,
  i: 3,
  j: 3,
  k: 7,
  l: 3,
  m: 12,
  n: 8,
  o: 8,
  p: 8,
  q: 8,
  r: 5,
  s: 7,
  t: 5,
  u: 8,
  v: 7,
  w: 11,
  x: 7,
  y: 7,
  z: 7,
  '{': 5,
  '|': 3,
  '}': 5,
  '~': 9,
};
export const s16 = {
  '0': 10,
  '1': 10,
  '2': 10,
  '3': 10,
  '4': 10,
  '5': 10,
  '6': 10,
  '7': 10,
  '8': 10,
  '9': 10,
  '!': 5,
  ' ': 4,
  '"': 7,
  '#': 10,
  $: 10,
  '%': 14,
  '&': 11,
  "'": 4,
  '(': 6,
  ')': 6,
  '*': 7,
  '+': 10,
  ',': 4,
  '-': 7,
  '.': 4,
  '/': 5,
  ':': 4,
  ';': 4,
  '<': 10,
  '=': 10,
  '>': 10,
  '?': 8,
  '@': 14,
  A: 10,
  B: 10,
  C: 11,
  D: 11,
  E: 9,
  F: 9,
  G: 12,
  H: 12,
  I: 4,
  J: 8,
  K: 10,
  L: 9,
  M: 14,
  N: 12,
  O: 12,
  P: 10,
  Q: 12,
  R: 10,
  S: 10,
  T: 10,
  U: 11,
  V: 10,
  W: 15,
  X: 11,
  Y: 10,
  Z: 10,
  '[': 6,
  '\\': 5,
  ']': 6,
  '^': 10,
  _: 9,
  '`': 8,
  a: 9,
  b: 10,
  c: 9,
  d: 10,
  e: 9,
  f: 5,
  g: 9,
  h: 9,
  i: 4,
  j: 4,
  k: 8,
  l: 4,
  m: 14,
  n: 9,
  o: 9,
  p: 9,
  q: 9,
  r: 6,
  s: 8,
  t: 6,
  u: 9,
  v: 8,
  w: 12,
  x: 8,
  y: 8,
  z: 8,
  '{': 6,
  '|': 4,
  '}': 6,
  '~': 10,
};
