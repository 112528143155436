import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { FilePreviewType } from './../file-preview-entry/file-preview-entry.component';
import { OrderType } from '../../providers/request/order-type.enum';

/**
 * 表格配置项
 *
 * @export
 * @class TableConfig
 *
 */
export class TableConfig {
  /**
   * 表格标题，可选
   *
   * （1）有：独立表格标题，可能为TAB标签页
   *
   * （2）无：页面名称代表表格标题
   *
   * @type {string}
   * @memberof TableConfig
   */
  title?: string;

  /**
   * 是否支持全选，默认不支持
   *
   * @type {boolean}
   * @memberof TableConfig
   */
  hasCheckAll?: boolean = false;

  /**
   * 是否支持序号，默认不支持
   *
   * @type {boolean}
   * @memberof TableConfig
   */
  hasIndex?: boolean = false;

  /**
   * 从左开始哪几列冻结
   *
   * 如果存在checkAll和index，则freezeColumn从自定义列开始算
   *
   * @type {boolean}
   * @memberof TableConfig
   */
  freezeColumn?: number = 0;

  /**
   * 所有列的配置项。如果存在index和checkAll，则需要包含这两列
   *
   * @type {TableColumnConfig[]}
   * @memberof TableConfig
   */
  columns: TableColumnConfig[] = [];

  rowConfig?: TableRowConfig;

  headerSize?: number = 14;

  bodySize?: number = 14;

  hideQuery?: boolean = false;
}

export class TableRowConfig {
  disabled?: (row: number) => boolean;
  blacklineUnder?: (row: number) => boolean;
  disableStyle?: string;
}

export class TableColumnConfig {
  /**
   * 列名称
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  name!: string;
  /**
   * 数据类型
   *
   * @type {TableColumnType}
   * @memberof TableColumnConfig
   */
  type!: TableColumnType;

  /**
   * 搜索数据类型
   *
   * @type {FormColumnType}
   * @memberof FormColumnConfig
   */
  queryType?: TableColumnType;
  /**
   * 数据来自哪个字段
   *
   * @type {string[]}
   * @memberof TableColumnConfig
   */
  key!: string;

  /**
   * 可显示的宽度
   *
   * @type {number}
   * @memberof TableColumnConfig
   */
  width!: number;

  /**
   * 是否可排序。
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  sortable?: boolean = false;

  sortKey?: string;

  /**
   * 是否可排序。
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  sortDefault?: OrderType = OrderType.DESC;

  sortOrder?: number;

  /**
   * 是否可编辑
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  editable?: (row: number) => boolean;

  /**
   * 是否可搜索
   *
   * 隐含条件：只有文本，数字，时间，选项这四种数据类型可搜索
   *
   * 文本：默认一个输入框，只能like搜索
   *
   * 数字、时间：默认两个输入框，范围搜索
   *
   * 选项：默认一个输入框，全部或者下拉选择
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  queryable?: boolean = false;

  queryKey?: string;

  queryKey2?: string;

  /**
   * 映射选项：仅列为映射时有效
   *
   * @type {{ value: string | number; name: string }[]}
   * @memberof TableColumnConfig
   */
  options?: TableColumnOption[] = [];

  searchOptions?: TableColumnOption[] = [];

  treeNodes?: NzCascaderOption[];

  undefinedOptionHint?: string = '未知';

  /**
   * 表头超链
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  headerLink?: string = '';

  /**
   * 表头释义
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  headerComment?: string = '';

  /**
   * 表体超链
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  // bodyLink?: string = '';

  /**
   * 表体示意key:
   * 如果表体式意是一个复杂的数据结构，建议将数据处理之后，将其作为数据列表中的一个字段传入，然后通过该字段获取html
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  bodyPopoverComponentKey?: string = '';

  bodyToolTipKey?: string = '';

  /**
   * 表体对齐方式
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  align?: 'center' | 'left' | 'right' = 'center';

  /**
   * 操作列表
   *
   * @type {TableActionConfig}
   * @memberof TableColumnConfig
   */
  actions?: TableActionConfig[];

  /**
   * 动态操作列表
   *
   * @type {TableActionConfig}
   * @memberof TableColumnConfig
   */
  dynamicActions?: TableDynamicActionConfig[];

  /**
   * 展示样式
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  patternStr?: string;

  /**
   * 展示样式
   *
   * @type {(row: number) => string}
   * @memberof TableColumnConfig
   */
  dynamicPatternStr?: (row: number) => string;

  /**
   * 展示样式
   *
   * @type { [key: string]: string }
   * @memberof TableColumnConfig
   */
  statusColorPatterns?: { [key: string]: string };

  /**
   * 时间搜索样式
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  timeFormat?: string;

  /**
   * 时间搜索范围最小值
   *
   * @type {Date}
   * @memberof TableColumnConfig
   */
  minTime?: Date;

  /**
   * 时间搜索范围最大值
   *
   * @type {Date}
   * @memberof TableColumnConfig
   */
  maxTime?: Date;

  /**
   * 文件预览，仅类型为file时有效
   *
   * @type {FilePreviewType}
   * @memberof FilePreviewType
   */
  fileType?: FilePreviewType;

  fileLimit?: number;

  fileCantRemove?: boolean;

  cover?: (row: number) => string;

  /**
   * 其他预览
   *
   * @type {TableActionConfig}
   * @memberof TableColumnConfig
   */
  preview?: TableActionConfig;

  columnSize?: number = 14;

  /**
   * 时间搜索格式： month/day/year
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  mode?: 'year' | 'month' | 'date' = 'date';

  nanString?: string = 'NaN';

  dynamicNanString?: (row: number) => string;

  placeholder?: string = '';

  required?: boolean = false;

  // getValue?: (row: number) => any;

  /**
   * 分割线 在单元格后面添加分割线
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  separator?: boolean = false;

  /**
   * 当列类型为location时生效
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  defaultLocationId?: string;

  locationMode?: 'all';

  /**
   * 分组：如果和上一列分组相同，则扩展到上一列
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  group?: string;

  groupWidth?: number;

  /**
   * 是否可上传
   *
   * @type {boolean}
   * @memberof TableColumnConfig
   */
  uploadStatus?: (row: number) => boolean;

  uploadText?: (row: number) => string;

  upload?: (event: any, row: number) => void;

  view?: (row: number) => void;

  uploadStyle?: (row: number) => string;
}

export class TableActionConfig {
  /**
   * 操作按钮
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  name?: string;

  style?: '' | 'primary' | 'danger' | 'success' | 'warning' | 'light' | 'grey';

  callback!: (row: number) => void;

  hideUnderline?: (row: number) => boolean;
}

export class TableDynamicActionConfig {
  /**
   * 动态操作按钮
   *
   * @type {string}
   * @memberof TableColumnConfig
   */
  name?: (row: number) => string;

  style?: (
    row: number
  ) => '' | 'primary' | 'danger' | 'success' | 'warning' | 'light' | 'grey';

  callback!: (row: number) => void;
}

export class TableColumnSearchConfig {
  sort: OrderType | undefined;
  sortDefault?: OrderType;
  value: any;
  value2?: any;
}

export type TableColumnOption = {
  value: string | number | boolean;
  label: string;
};

export enum TableColumnType {
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  text = 'text',
  location = 'location',
  digital = 'digital',
  amount = 'amount',
  percent = 'percent',
  status = 'status',
  options = 'options',
  actions = 'actions',
  file = 'file',
  imgAction = 'imgAction',
  tree = 'tree',
  log = 'log',
  upload = 'upload',
  // rule = 'rule',
  // subject = 'subject',
  // subjectSummary = 'subjectSummary',
}
